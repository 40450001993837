import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Radio, Tooltip } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { NotificationTypesContext } from "../../context/notificationTypeContext";
import {
  CreateNotificationTypeModal,
  UpdateNotificationTypeModal,
  ConfirmationModal,
} from "../../components/Modals/index";
import { getFormattedData } from "../../utils/index";
import DataLoader from "../../components/dataLoader";
const { Title } = Typography;

function NotificationTypeManagement() {
  const {
    getNotificationTypesList,
    notificationTypesList,
    deleteNotificationType,
    updateNotificationType,
    createNotificationType,
    loading,
  } = useContext(NotificationTypesContext);
  const [showCreateNotificationTypeModal, setShowCreateNotificationTypeModal] =
    useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [filteredNotificationTypes, setFilteredNotificationTypes] = useState(
    []
  );
  const [filterStatus, setFilterStatus] = useState("all");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [page, setPage] = useState(1);
  const { meta, data } = notificationTypesList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  console.log("NotificationTypes", notificationTypesList);
  useEffect(() => {
    getNotificationTypesList(page, true);
  }, [page]);

  useEffect(() => {
    const temp = notificationTypesList?.data?.filter((crew) =>
      filterStatus === "active"
        ? crew?.is_active
        : filterStatus === "inactive"
        ? !crew?.is_active
        : crew
    );
    console.log("filteredCrew", temp);
    setFilteredNotificationTypes(temp);
  }, [filterStatus, data]);
  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, value) => {
        return <span>{getFormattedData(data)}</span>;
      },
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      render: (data, value) => {
        return (
          <span className="image-box">
            <img
              src={data}
              alt="icon"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (data, record) => {
        return (
          <div className="actions">
            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update Notification Type">
                <EditOutlined />
              </Tooltip>
            </span>
            <span>
              {!data?.is_default && (
                <Tooltip title="Delete Notification Type">
                  <DeleteOutlined onClick={() => handleConfirmModal(record)} />
                </Tooltip>
              )}
            </span>
          </div>
        );
      },
    },
  ];

  const handleChangeFilter = (e) => {
    setFilterStatus(e.target.value);
  };

  //HANDLERS
  const handleCreateModal = () => {
    setShowCreateNotificationTypeModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateNotificationTypeModal(false);
  };

  const handleCreateNotificationType = (name, icon) => {
    createNotificationType(
      {
        name,
        icon,
      },
      page,
      true
    );
  };
  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };
  const handleUpdateData = (data, id) => {
    updateNotificationType(data, id, page, true);
  };

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteNotificationType(id, page, true);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <button onClick={handleCreateModal}>
                Create Notification Type
              </button>
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>Notification Types</Title>
                  <Paragraph className="lastweek">
                    {/* done this month<span className="blue">40%</span> */}
                  </Paragraph>
                </div>
                {/* <div className="ant-filtertabs mb-24">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <Radio.Group
                      onChange={handleChangeFilter}
                      defaultValue="all"
                    >
                      <Radio.Button value="all">ALL</Radio.Button>
                      <Radio.Button value="active">ACTIVE</Radio.Button>
                      <Radio.Button value="inactive">IN-ACTIVE</Radio.Button>
                    </Radio.Group>
                  </div>
                </div> */}
              </div>
              <AdminDataTable
                data={filteredNotificationTypes}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {showCreateNotificationTypeModal && (
        <CreateNotificationTypeModal
          handleClose={handleCloseCreateModal}
          addType={handleCreateNotificationType}
        />
      )}
      {showUpdateModal && (
        <UpdateNotificationTypeModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Notification Type?"}
          description={
            "Are you sure you want to delete this notification type?"
          }
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}
      {loading && <DataLoader />}
    </>
  );
}

export default NotificationTypeManagement;
