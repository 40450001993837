import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Tooltip } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { EditOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { SubscriptionContext } from "../../context/subscriptioContext";
import { UpdateSubscriptionModal } from "../../components/Modals/index";
import DataLoader from "../../components/dataLoader";
import { getFormattedDateTime, getFormattedData } from "../../utils";
const { Title } = Typography;

function SubcriptionPlans() {
  const {
    getSubscriptionsList,
    updateSubscription,
    subscriptionLists,
    laoding,
  } = useContext(SubscriptionContext);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [page, setPage] = useState(1);
  const { meta, data } = subscriptionLists;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
    showSizeChanger: false,
  };

  useEffect(() => {
    getSubscriptionsList(page, true);
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (data, value) => {
        return <span>{getFormattedData(data)}</span>;
      },
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      render: (data, value) => {
        return <span>{getFormattedData(data)}</span>;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (data, value) => {
        return <span>{data ? `$${data}` : "-"}</span>;
      },
    },
    {
      title: "Save Amount",
      dataIndex: "save_amount",
      key: "save_amount",
      render: (data, value) => {
        return <span>{data ? `$${data}` : "-"}</span>;
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (data, value) => {
        return <span>{data ? `$${data}` : "-"}</span>;
      },
    },
    {
      title: "Popular",
      dataIndex: "is_popular",
      key: "is_popular",
      render: (data, value) => {
        return <span>{data ? `yes` : "no"}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <div className="actions">
            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update Subscription">
                <EditOutlined />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  //HANDLERS
  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };
  const handleUpdateData = (data, id) => {
    updateSubscription(data, id, page, true);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn"></div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>Subscription Plans</Title>
                  <Paragraph className="lastweek"></Paragraph>
                </div>
              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {showUpdateModal && (
        <UpdateSubscriptionModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}
      {laoding && <DataLoader />}
    </>
  );
}

export default SubcriptionPlans;
