import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Typography,
  Col,
  Row,
  Button,
  Tooltip,
  Form,
  DatePicker,
} from "antd";
import { FilterFilled } from "@ant-design/icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  ConfirmationModal,
  UpdateFeedbackOptionModal,
  CreateFeedbackOptionModal,
} from "../../components/Modals/index";
import { EditOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { FeedbackOptionsContext } from "../../context/feedbackOptionContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataLoader from "../../components/dataLoader";
import { getFormattedDate } from "../../utils";
import FilterDrawer from "../../components/filterDrawer";

function SystemFeedbackOptions() {
  const { RangePicker } = DatePicker;
  const {
    getFeedbackOptionsList,
    feedbackOptionsList,
    deleteFeedbackOption,
    updateFeedbackOption,
    createFeedbackOption,
    loading,
  } = useContext(FeedbackOptionsContext);
  const [selectedData, setselectedData] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [page, setPage] = useState(1);

  const { meta, data } = feedbackOptionsList;
  const { Title, Paragraph } = Typography;

  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };
  const [form] = Form.useForm();

  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    if (Array.isArray(credentials.date)) {
      credentials["from"] = getFormattedDate(credentials.date[0]);
      credentials["to"] = getFormattedDate(credentials.date[1]);
    }
    setFilterOpen(false);
    setFilteredData(credentials);
  };
  const handleResetForm = () => {
    form.resetFields();
    setFilteredData(null);
    form.setFieldsValue({
      date: undefined,
    });
    setFilterOpen(false);
  };
  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  useEffect(() => {
    let filterQuery = "";
    if (filteredData) {
      Object.keys(filteredData).forEach(function (key) {
        if (filteredData[key] && key !== "date") {
          filterQuery += `&${key}=${filteredData[key]}`;
        }
      });
    }
    getFeedbackOptionsList(page, true, filterQuery);
  }, [page, filteredData]);

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: 1,
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: 2,
      render: (data, value) => <span>{data}</span>,
    },
    {
      title: "Action",
      key: 3,
      render: (text, record) => {
        return (
          <div className="actions">
            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update System Feedback Option">
                <EditOutlined />
              </Tooltip>
            </span>
            <span>
              <Tooltip title="Delete System Feedback Option">
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => handleConfirmModal(record)}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  const drawerSec = () => (
    <>
      {isFilterOpen && (
        <Form
          form={form}
          className="form"
          initialValues={filteredData}
          onFinish={handleFormFinish}
        >
          <label className="form-lbl">Date:</label>
          <Form.Item name={"date"}>
            <RangePicker className="form-input" />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              className="form-btn"
              style={{ minWidth: "100%" }}
            >
              Apply Filters
            </Button>
            <span className="form-rm-btn" onClick={handleResetForm}>
              Reset Filters
            </span>
          </Form.Item>
        </Form>
      )}
    </>
  );

  const handleCreateModal = () => {
    setShowCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };
  const handleCreateData = (name) => {
    createFeedbackOption({ name }, page, true);
  };

  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };

  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };

  const handleUpdateData = (data, id) => {
    updateFeedbackOption(data, id, page, true);
  };

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteFeedbackOption(id, page, true);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <button onClick={handleCreateModal}>Create Option</button>
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>System Feedback Options</Title>
                  <Paragraph className="lastweek"></Paragraph>
                </div>

                <Button className="filter-btn" onClick={filterHandler}>
                  <FilterFilled />
                  Filter
                </Button>
              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
      {showCreateModal && (
        <CreateFeedbackOptionModal
          handleClose={handleCloseCreateModal}
          onSubmit={handleCreateData}
        />
      )}
      {showUpdateModal && (
        <UpdateFeedbackOptionModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete System Feedback Option?"}
          description={
            "Are you sure you want to delete this system feedback option?"
          }
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}
      {loading && <DataLoader />}
    </>
  );
}

export default SystemFeedbackOptions;
