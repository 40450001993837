import React, { useState } from "react";
import { Modal, Input } from "antd";
const UpdateFeedbackOptionModal = ({
  handleClose,
  selectedData,
  updateData,
}) => {
  const [name, setName] = useState(selectedData?.name ?? "");
  const handleUpdateData = () => {
    updateData({ name }, selectedData?.id);
    handleClose();
  };

  const handleChange = (e) => {
    setName(e?.target?.value);
  };
  return (
    <Modal
      visible={true}
      title={<span>Update System Feedback Option </span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <label className="title">Name</label>
          <Input className="field" onChange={handleChange} value={name} />
        </div>
        <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            Close
          </button>
          <button
            className="submit"
            style={{ opacity: name.length >= 1 ? 1 : 0.3 }}
            disabled={name.length >= 1 ? false : true}
            onClick={handleUpdateData}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateFeedbackOptionModal;
