import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Typography,
  Col,
  Row,
  Form,
  Button,
  Input,
  Select,
  Tooltip,
} from "antd";
import { FilterFilled, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { WingTypeContext } from "../../context/WingTypeContext";
import { AirCraftContext } from "../../context/AircraftContext";
import { ManufactureContext } from "../../context/ManufacturerContext";
import {
  CreateAircraftModal,
  UpdateAircraftModal,
  ConfirmationModal,
} from "../../components/Modals/index";
import { getFormattedDateTime, getFormattedData } from "../../utils";
import DataLoader from "../../components/dataLoader";
import FilterDrawer from "../../components/filterDrawer";
const { Title } = Typography;

function AircraftManagement() {
  const { getWingTypesList, wingTypeLists } = useContext(WingTypeContext);
  const { getManufacturesList, manufactureLists } =
    useContext(ManufactureContext);
  const {
    getAirCraftsList,
    createAirCraft,
    updateAirCraft,
    deleteAirCraft,
    aircraftLists,
    loading,
  } = useContext(AirCraftContext);
  const [showCreateAircraftModal, setShowCreateAircraftModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [wingTypeData, setwingTypeData] = useState([]);
  const [manufacturerData, setmanufacturerData] = useState([]);
  const [page, setPage] = useState(1);
  const { meta, data } = aircraftLists;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
    showSizeChanger: false,
  };
  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    setFilterOpen(false);
    setFilteredData(credentials);
  };
  const handleResetForm = () => {
    form.resetFields();
    setFilteredData(null);
    form.setFieldsValue({
      name: undefined,
      wing_type_id: undefined,
      manufacturer_id: undefined,
    });
    setFilterOpen(false);
  };

  useEffect(() => {
    getWingTypesList(1, false);
    getManufacturesList(1, false);
  }, []);

  useEffect(() => {
    setwingTypeData(
      Array.isArray(wingTypeLists) ? wingTypeLists : wingTypeLists.data
    );
  }, [wingTypeLists]);

  useEffect(() => {
    setmanufacturerData(
      Array.isArray(manufactureLists) ? manufactureLists : manufactureLists.data
    );
  }, [manufactureLists]);

  useEffect(() => {
    let filterQuery = "";
    if (filteredData) {
      Object.keys(filteredData).forEach(function (key) {
        if (filteredData[key] && key !== "date") {
          filterQuery += `&${key}=${filteredData[key]}`;
        }
      });
    }
    getAirCraftsList(page, true, filterQuery);
  }, [page, filteredData]);

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, value) => {
        return <span>{getFormattedData(data)}</span>;
      },
    },
    {
      title: "Wing type",
      dataIndex: "wing_type",
      key: "wing_type",
      render: (data, value) => {
        return <span>{getFormattedData(data?.name)}</span>;
      },
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      render: (data, value) => {
        return <span>{getFormattedData(data?.name)}</span>;
      },
    },
    {
      title: "Activated",
      dataIndex: "is_active",
      key: "is_active",
      render: (data, value) => {
        console.log("aaa", data);
        return <span>{data === true ? "Yes" : "No"}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const { slug, id } = record;
        return (
          <div className="actions">
            <Link
              className="preview"
              to={`aircraft/${slug}/${id}`}
              style={{ marginLeft: 15 }}
            >
              <Tooltip title="View Aircraft">
                <FontAwesomeIcon icon={faEye} />
              </Tooltip>
            </Link>
            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update Aircraft">
                <EditOutlined />
              </Tooltip>
            </span>
            <span>
              <Tooltip title="Delete Aircraft">
                <DeleteOutlined onClick={() => handleConfirmModal(record)} />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  const drawerSec = () => (
    <>
      {isFilterOpen && (
        <Form
          form={form}
          className="form"
          initialValues={filteredData}
          onFinish={handleFormFinish}
        >
          <label className="form-lbl">Name:</label>
          <Form.Item name={"name"}>
            <Input type="text" />
          </Form.Item>
          <label className="form-lbl">Wing type:</label>
          <Form.Item name={"wing_type_id"}>
            <Select
              placeholder="Filter by wing type"
              name="wing_type_id"
              className="form-select"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {wingTypeData.map((data, index) => (
                <Select.Option value={data.id} key={index}>
                  {data.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <label className="form-lbl">Manufacturer:</label>
          <Form.Item name={"manufacturer_id"}>
            <Select
              placeholder="Filter by manufacturer"
              name="manufacturer_id"
              className="form-select"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {manufacturerData.map((data, index) => (
                <Select.Option value={data.id} key={index}>
                  {data.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              className="form-btn"
              style={{ minWidth: "100%" }}
            >
              Apply Filters
            </Button>
            <span className="form-rm-btn" onClick={handleResetForm}>
              Reset Filters
            </span>
          </Form.Item>
        </Form>
      )}
    </>
  );

  //HANDLERS

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  const handleCreateModal = () => {
    setShowCreateAircraftModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateAircraftModal(false);
  };

  const handleCreateAircraft = (data) => {
    createAirCraft(data, page, true);
  };
  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };
  const handleUpdateData = (data, id) => {
    updateAirCraft(data, id, page, true);
  };

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteAirCraft(id, page, true);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <button onClick={handleCreateModal}>Add Aircraft</button>
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <Title level={5}>Aircrafts</Title>
                <Button className="filter-btn" onClick={filterHandler}>
                  <FilterFilled />
                  Filter
                </Button>
              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {showCreateAircraftModal && (
        <CreateAircraftModal
          handleClose={handleCloseCreateModal}
          addType={handleCreateAircraft}
        />
      )}
      {showUpdateModal && (
        <UpdateAircraftModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Aircraft?"}
          description={"Are you sure you want to delete this aircraft?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
      {loading && <DataLoader />}
    </>
  );
}

export default AircraftManagement;
