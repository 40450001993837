import axios from "axios";
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";

export const RolesContext = createContext();

export const RolesContextProvider = ({ children }) => {
  const [rolesList, setRolesList] = useState([]);
  const [singleRole, setSingleRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const getRolesList = async (page = 1, paginateStatus = true, limit) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/roles?page=${page}&paginate=${paginateStatus}`,
        BEARER_TOKEN_HEADERS
      );
      console.log("roles result", res?.data);
      setLoading(false);
      setRolesList(res?.data);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getSingleRole = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/roles/${id}`,
        BEARER_TOKEN_HEADERS
      );
      console.log("single role result", res?.data);
    } catch (err) {
      errorToast(err);
    }
  };

  const createRole = async (data) => {
    console.log("role data", data);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/roles`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("create role result", res?.data, res);
      getRolesList();
      toast.success(res?.data?.message);
    } catch (err) {
      errorToast(err);
    }
  };

  const updateRole = async (data, id) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/roles/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("update role result", res?.data, rolesList);
      getRolesList();
      toast.success(res?.data?.message);
    } catch (err) {
      errorToast(err);
    }
  };

  const deleteRole = async (id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/roles/${id}`,
        BEARER_TOKEN_HEADERS
      );
      console.log("delete role result", res?.data);
      getRolesList();
      toast.success(res?.data?.message);
    } catch (err) {
      errorToast(err);
    }
  };

  return (
    <RolesContext.Provider
      value={{
        rolesList,
        singleRole,
        getSingleRole,
        getRolesList,
        createRole,
        updateRole,
        deleteRole,
        loading,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};
