export const NOTIFICATION_USER_TYPE = [
  {
    id: "crew",
    name: "Crew",
  },
  {
    id: "client",
    name: "Client",
  },
  {
    id: "both",
    name: "Both",
  },
];
export const NOTIFICATION_PLATFORM_TYPE = [
  {
    id: "android",
    name: "Android",
  },
  {
    id: "ios",
    name: "IOS",
  },
  {
    id: "both",
    name: "Both",
  },
];

export const NOTIFICATION_MEDIUM_TYPE = [
  {
    id: "push",
    name: "Push",
  },
  {
    id: "email",
    name: "Email",
  },
  {
    id: "both",
    name: "Both",
  },
];
