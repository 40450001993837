import React, { useState, useContext } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { imageValidation } from "../../utils";
import { GenericContext } from "../../context/genericContext";
import "./styles.scss";
const ImageUploader = ({ setImage, image, folder }) => {
  const { uploadImage } = useContext(GenericContext);
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState("");
  const imageExt = image ? image.split(".").pop() : "";
  const setLoadingToggle = () => {
    setLoading(false);
  };
  const setErrorToggle = (error) => {
    seterror(error);
  };

  const handleChange = (e) => {
    seterror("");
    if (loading) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      setLoading(true);
      if (imageValidation(file, setErrorToggle, setLoadingToggle)) {
        const data = new FormData();
        data.append("file", file);
        uploadImage(folder, data, (res) => {
          setLoading(false);
          console.log("res", res);
          if (res?.url) {
            setImage(res?.url);
          }
        });
      }
    }
  };
  const uploadButton = (
    <div className="upload-txt">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 5,
        }}
      >
        {loading ? "Uploading" : "Upload"}
      </div>
    </div>
  );
  return (
    <>
      <form
        className={`image-uploader-box ${image && "active"}`}
        encType="multipart/form-data"
      >
        {/* {!isOnlyImage && ( */}
        {/* <> */}
        <input type="file" onChange={handleChange} />
        {uploadButton}
        {/* </> */}
        {/* )} */}
        {image && <img src={image} alt="" />}
      </form>
      <p className="errorMessage">{error}</p>
    </>
  );
};

export default ImageUploader;
