import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/index";
import { Row, Col, Card, Typography, Collapse, Tabs, Modal, Space, Avatar, Tooltip } from "antd";
import AdminDataTable from "../../Layouts/sharedAdminLayout/partials/data-table";
import { ClientContext } from "../../../context/ClientContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getFormattedDateTime } from "../../../utils/index";

const Client = () => {
  const { TabPane } = Tabs;
  const { clientId } = useParams();
  const { Title, Paragraph } = Typography;
  const { getSingleClient, data, singleClientLoader } =
    useContext(ClientContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState("");

  const handlePhotoClick = (photoUrl) => {
    setModalVisible(true);
    setLargeImageUrl(photoUrl);
  };
  useEffect(() => {
    getSingleClient(clientId);
  }, []);
  // const { data } = singleClient;
  if (singleClientLoader) {
    return <Loader />;
  } else {
    const tab1Sec = () => (
      <>
        <Row gutter={[6, 0]}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <div className="profile-photo centered-content">
              <Space direction="vertical" align="center" size={100}>
                <Space wrap size={100}>
                  {data?.user?.photo_thumb !== null &&
                  data?.user?.photo_thumb !== "" ? (
                    <Avatar
                      size={150}
                      src={data?.user?.photo_thumb}
                      onClick={() => handlePhotoClick(data?.user?.photo)}
                    />
                  ) : (
                    <Avatar size={150} icon={<UserOutlined />} />
                  )}
                </Space>
              </Space>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={18} xl={18}>
            <div className="profile-details">
              <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Company Name</h5>
                  <p className="detail-description">
                    {data?.company_name}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">User Name</h5>
                  <p className="detail-description">
                    {data?.user?.firstname} {data?.user?.lastname}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Rating</h5>
                  <p className="detail-description">{data?.rating}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Country</h5>
                  <p className="detail-description">{data?.country?.name}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Contact Person</h5>
                  <p className="detail-description">
                    {data?.contact_person}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Phone Number</h5>
                  <p className="detail-description">
                    {data?.user?.phone_code} {data?.user?.phone}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Email Address</h5>
                  <p className="detail-description">{data?.user?.email}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Total Jobs Count</h5>
                  <p className="detail-description">{data?.jobs_count}</p>
                </Col>
                <Col xs={24} sm={24} md={6}>
                  <h5 className="detail-title">Joined at</h5>
                  <p className="detail-description">{getFormattedDateTime(data?.created_at)}</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {modalVisible && (
          <div className="layout-content">
            <div className="mb-24">
              <Card bordered={false} className="criclebox">
                {/* Rest of the code */}
              </Card>
            </div>

            <Modal
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
              <img
                src={largeImageUrl}
                alt="Large Avatar"
                style={{ width: "100%" }}
              />
            </Modal>
          </div>
        )}
      </>
    );
    const tab2sec = () => {
      const columns = [
        {
          title: "Trip No",
          dataIndex: "trip_id",
          key: "trip_id",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Crew Type",
          dataIndex: "crew_type",
          key: "crew_type",
          render: (data, value) => <span>{data?.name}</span>,
        },
        {
          title: "Status",
          dataIndex: "job_status",
          key: "job_status",
          render: (data, value) => <span>{data?.name}</span>,
        },
        {
          title: "Action",
          key: "action",
          render: (text, record) => {
            const { slug, id } = record;
            return (
              <div className="actions">
                <Link to={`/admin/trip/${slug}/${id}`} className="preview">
                  <Tooltip title="View job">
                    <FontAwesomeIcon icon={faEye} />
                  </Tooltip>
                </Link>
              </div>
            );
          },
        },
      ];
      return <AdminDataTable columns={columns} data={data?.jobs} />;
    };

    const noData = (message) => <p className="no-data">{message}</p>;
    return (
      <div className="layout-content">
        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <Title level={5}>{data?.headline}</Title>
            <Paragraph className="lastweek" style={{ marginBottom: 5 }}>
              {data?.brief}
            </Paragraph>
            <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
              <TabPane tab="Detail" key="1">
                {tab1Sec()}
              </TabPane>
              <TabPane tab="Jobs" key="2">
                {data?.jobs && data?.jobs?.length >= 1
                  ? tab2sec()
                  : noData("Jobs Not Available")}
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    );
  }
};

export default Client;
