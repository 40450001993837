import { toast } from "react-toastify";
import moment from "moment-timezone";

export const BEARER_TOKEN_HEADERS = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("nvoii_token"),
  },
};

export const BEARER_TOKEN_IMAGE_HEADERS = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("nvoii_token"),
    "Content-Type": "multipart/form-data",
  },
};

export const imageValidation = (file, setError, setLoading) => {
  let res = true;
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    setLoading();
    setError("You can only upload JPG/PNG file!");
    res = false;
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  // if (!isLt2M) {
  //   res = false;
  //   setLoading();
  //   setError("Image must smaller than 2MB!");
  // }

  return res;
};
export const errorToast = (err) => {
  console.log(err);
  console.log(err.response);
  if (err.response?.status === "401") {
    localStorage.removeItem("nvoii_token");
    localStorage.removeItem("nvoii_role");
  }
  if (err.response.data.message) {
    toast.error(err.response.data.message);
  } else if (err.response.data.errors[0].message) {
    toast.error(err.response.data.errors[0].message);
  }
};
export const getFormattedDateTime = (date) => {
  if (date) return moment(date).tz("UTC").format(process.env.REACT_APP_DATE_FORMAT);
  return "-";
};
export const getFormattedDate = (date) => {
  if (date) return moment(date).tz("UTC").format(process.env.REACT_APP_API_DATE_FORMAT);
  return "-";
};
export const getFormattedData = (data) => {
  if (data) return data;
  return "-";
};
// moment().format("MMMM Do YYYY, h:mm:ss a");
