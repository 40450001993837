import React, { useState, createContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";

export const BannersContext = createContext();

export const BannersContextProvider = ({ children }) => {
  const [bannersList, setBannersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const getBannersList = async (page, paginationStatus, query = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/banners?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      setLoading(false);
      setBannersList(res?.data);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const createBanners = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/banners`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("banners list result", res?.data);
      setLoading(false);
      getBannersList(page, paginationStatus);
      toast.success(res?.data?.message);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateBanners = async (data, id, page, paginationStatus) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/banners/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      getBannersList(page, paginationStatus);
      toast.success(res?.data?.message);
    } catch (err) {
      console.log(err);
      errorToast(err);
    }
  };

  const deleteBanners = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/banners/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getBannersList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <BannersContext.Provider
      value={{
        getBannersList,
        bannersList,
        deleteBanners,
        updateBanners,
        createBanners,
        loading,
      }}
    >
      {children}
    </BannersContext.Provider>
  );
};
