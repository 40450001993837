import React, { useState, useContext, useEffect } from "react";

import {Card,Typography,Col,Row,Radio,Button,Tooltip,Form,Select,DatePicker,Input,Steps,Space} from "antd";
import { EnvironmentOutlined, GlobalOutlined, UsergroupAddOutlined, DingdingOutlined, DollarOutlined  } from "@ant-design/icons";
import DataLoader from "../../../components/dataLoader";
import { Redirect } from "react-router-dom";

import { GenericContext } from "../../../context/genericContext";
import { AirportsContext } from "../../../context/AirportContext";
import { CrewTypeContext } from "../../../context/CrewTypeContext";
import { AirCraftContext } from "../../../context/AircraftContext";
import { ManufactureContext } from "../../../context/ManufacturerContext";
import { JobsContext } from "../../../context/JobsContext";
import { getFormattedDate } from "../../../utils";
import { ClientContext } from "../../../context/ClientContext";


const CreatJob = () => {

  const locations = [{id:1,name:"US Only"},{id:2,name:"Worldwide"}]
  const [finalTripCost, setFinalTripCost] = useState(0);
  const [locationId, setLocationId] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [crewMember,setCrewMember] = useState(null);

  const { getCountries, countries, getFlightHours, flightHours, getUsStates, usStates, getPaymentTerms, paymentTerms, getFlightExperiences,flightExperiences } = useContext(GenericContext);
  const { getAirportsList, airportsList } = useContext(AirportsContext);
  const { getCrewTypesList, crewTypeLists } = useContext(CrewTypeContext);
  const { getAirCraftsList, aircraftLists } = useContext(AirCraftContext);
  const { getManufacturesList, manufactureLists } = useContext(ManufactureContext);
  const { getClientList, clientList} = useContext(ClientContext);
  const { createJob, loading, jobsRedirect } = useContext(JobsContext);
  const { Title, Paragraph } = Typography;
  
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    getCountries();
    getFlightHours();
    getUsStates();
    getPaymentTerms();
    getClientList();
    getAirportsList(1,true);
    getCrewTypesList(1,true);
    getAirCraftsList(1,true);
    getManufacturesList();
    getFlightExperiences();
  }, []);
  
  const handleSearchAirport = async (value) => {
    let q = `&q=${value}`;
    getAirportsList(1,true,q);
  }

  const handleChangeManufacturer = (value) => {
    let q = `&manufacturer_id=${value}`;
    getAirCraftsList(1,true,q);
  }

  const handleChangeLocation = (value) => {
    console.log('locationID',value)
    setLocationId(value);
  }

  const handleChangeCrew = (value) => {
    console.log('Crew ID',value)
    setCrewMember(value);
  }

  const handleCalculateTripCost = () => {

    const data = form.getFieldsValue();
       
    let from = data.date[0]._d;
    let to = data.date[1]._d;
      
    from = new Date(from.getFullYear(), from.getMonth() - 1, from.getDate());
    to = new Date(to.getFullYear(), to.getMonth() - 1, to.getDate());
    // let totalDays = Math.round((to - from) / (1000 * 60 * 60 * 24) + 1).toString();
    let totalDays = Math.round((to - from) / (1000 * 60 * 60 * 24) ).toString();
    totalDays = parseInt(totalDays)+parseInt(1);
    let daily_rate = data.daily_rate?data.daily_rate:0;
    let per_diem = data.per_diem?data.per_diem:0;

    console.log("Total Days",totalDays);
    let finalTripCost=(parseInt(daily_rate) + parseInt(per_diem)) * parseInt(totalDays);
    console.log("Final Trip Cost",finalTripCost);

    setFinalTripCost(finalTripCost?finalTripCost:0);
  }

  const handleFormSubmit = () => {

    const data = form.getFieldsValue();
    // console.log("first", data);

    let flags = data?.flags?.toString();
    let airports = "";
    if(flags != null && flags != ""){
      airports = data.departure_id+","+ flags +","+data.destination_id
    }else{
      airports = data.departure_id+","+data.destination_id;
    }
    let countries = 0;
    let states = 0;
    if(data?.location == 1){
      countries = "233";
      if(data?.states?.toString() != "" && data?.states != undefined ){
        states=data?.states?.toString()
      }
    }else{
      if(data?.countries?.toString() != "" && data?.countries != undefined){
        countries = data?.countries?.toString();
      }
    }

    let tempData = {
      job_type_id: 2,
      client_id: data.client_id,
      airports: airports,
      date_from:getFormattedDate(data.date[0]),
      date_to:getFormattedDate(data.date[1]),
      description:data.description,

      crew_type_id:data.crew_type_id,
      min_flight_hours_id:data.min_flight_hours_id?data.min_flight_hours_id:0,
      min_flight_experience_id:data.min_flight_experience_id?data.min_flight_experience_id:0,
      
      manufacturer_id:data.manufacturer_id,
      aircraft_id:data.aircraft_id,
      min_flight_hours_on_type_id:data.min_flight_hours_on_type_id?data.min_flight_hours_on_type_id:0,
      min_flight_experience_on_type_id:data.min_flight_experience_on_type_id?data.min_flight_experience_on_type_id:0,

      countries:countries,
      states:states,

      // daily_rate:data.daily_rate,
      // per_diem:data.per_diem,
      payment_term_id:data.payment_term_id?data.payment_term_id:0,
      avg_job_earning: finalTripCost,
      total_earnings:1200
    };

    if( data.daily_rate ) {
      tempData.daily_rate = data.daily_rate;
    }

    if( data.per_diem ) {
      tempData.per_diem = data.per_diem;
    }

    // console.log("TempData",tempData);
    createJob(tempData);
  };

  const handleNext = () => {
    setCurrentStep(currentStep+1);
  }
  const handlePrev = () => {
    setCurrentStep(currentStep-1);
  }

  if(jobsRedirect){
    return <Redirect to={"/admin/trips"} />;
  }else{

  
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
           <div className="add-btn">
              {/* <button onClick={handleCreateModal}>Create Trip</button> */}
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody p-20"
              style={{ minHeight: 360, padding: "20px 50px 50px 50px"}}
            >
      <Title level={5}>Post a Contract Job</Title>
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">

            <Steps 
              current={currentStep}
              style={{padding:"32px 16px"}}
            >
              <Steps.Step title="Trip" icon={<EnvironmentOutlined />} />
              <Steps.Step title="AirCraft General" icon={<DingdingOutlined />} />
              <Steps.Step title="Finalise" icon={<DollarOutlined />} />
            </Steps>

            {/* Step 1 Fields */}
            <Row gutter={"12"} className={currentStep==0?"stepActive":"stepInActive"}>

            <Col xs={24} sm={24}>
                <label className="title">
                  Client<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"client_id"}
                  rules={[
                    { required: true, message: "Please select Airport!" },
                  ]}
                >
                  <Select
                    placeholder="Select Client!"
                    name="client_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {clientList?.map((client, index) => (
                      <Option value={client?.id} key={index}>
                        {`${client?.user?.firstname} ${client?.user?.lastname} ( ${client?.user?.email} )`} 
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>


              <Col xs={24} sm={24}>
                <label className="title">
                  Departure Airport<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"departure_id"}
                  rules={[
                    { required: true, message: "Please select Airport!" },
                  ]}
                >
                  <Select
                    placeholder="Please search or select Airport!"
                    name="departure_id"
                    showSearch
                    filterOption={false}
                    onSearch={handleSearchAirport} // Call handleSearch on input change
                  >
                    {airportsList?.data?.map((airport, index) => (
                      <Option value={airport?.id} key={index}>
                        {airport?.icao} - {airport?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  LEGS <span style={{ color: "red" }}></span>
                </label>
                <Form.Item
                  name={"flags"}
                >
                  <Select
                    mode="multiple"
                    placeholder="Please search or select Airport!"
                    name="flags"
                    showSearch
                    filterOption={false}
                    onSearch={handleSearchAirport} // Call handleSearch on input change
                  >
                    {airportsList?.data?.map((airport, index) => (
                      <Option value={airport?.id} key={index}>
                        {airport?.icao} - {airport?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Destination Airport<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"destination_id"}
                  rules={[
                    { required: true, message: "Please select Airport!" },
                  ]}
                >
                  <Select
                    placeholder="Please search or select Airport!"
                    name="destination_id"
                    showSearch
                    filterOption={false}
                    onSearch={handleSearchAirport} // Call handleSearch on input change
                  >
                    {airportsList?.data?.map((airport, index) => (
                      <Option value={airport?.id} key={index}>
                        {airport?.icao} - {airport?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Dates<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"date"}
                  rules={[
                    { required: true, message: "Please Select Date Range!" },
                  ]}
                >
                  <RangePicker className="form-input" style={{width:"100%"}}/>

                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Description<span style={{ color: "red" }}></span>
                </label>
                <Form.Item
                  name={"description"}
                >
                <Input.TextArea
                  rows={5}
                  className="field"
                  name={"description"}
                  onChange={(e) => {
                    // setMessage(e.target.value);
                  }}
                  // value={message}
                  placeholder="Enter Message Here.."
                />
                </Form.Item>
              </Col>


            </Row>


            {/* Step 2 Fields */}
            <Row gutter={"12"} className={currentStep==1?"stepActive":"stepInActive"}>   
                <h4><strong>Type</strong></h4>
              <Col xs={24} sm={24}>
                <label className="title">
                  Manufacturer<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"manufacturer_id"}
                  rules={[
                    { required: true, message: "Please select Manufacturer!" },
                  ]}
                >
                  <Select
                    placeholder="Please search or select Manufacturer!"
                    name="manufacturer_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={handleChangeManufacturer}
                  >
                    {manufactureLists?.map((manufacture, index) => (
                      <Option value={manufacture?.id} key={index}>
                        {manufacture?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={24}>
                <label className="title">
                  Aircraft Make<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"aircraft_id"}
                  rules={[
                    { required: true, message: "Please select Aircraft!" },
                  ]}
                >
                  <Select
                    placeholder="Select Aircraft!"
                    name="aircraft_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {aircraftLists?.data?.map((aircraft, index) => (
                      <Option value={aircraft?.id} key={index}>
                        {aircraft?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <h4><strong>Crew</strong></h4>

              <Col xs={24} sm={24}>
                <label className="title">
                  Crew Member<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"crew_type_id"}
                  rules={[
                    { required: true, message: "Please select Crew Member!" },
                  ]}
                >
                  <Select
                    onChange={handleChangeCrew}
                    placeholder="Select Crew Member!"
                    name="crew_type_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {crewTypeLists?.data?.map((crewType, index) => (
                      <Option value={crewType?.id} key={index}>
                        {crewType?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>


            {/* Step 3 Fields */}
            <Row gutter={"12"} className={currentStep==2?"stepActive":"stepInActive"}>

              <Col xs={24} sm={24}>
                <div className="note text-center">
                      <p style={{maxWidth:"500px",margin:"0 auto"}}>
                        Professional tend to charge <strong>$1500-$3000</strong> for crewing contracts like yours. 
                        Experts may charge higher rates
                      </p>
                </div>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Daily Rate
                </label>
                <Form.Item
                  name={"daily_rate"}
                  rules={[
                    { required: false, message: "Please Insert Daily Rate!" },
                  ]}
                >
                  <Input onKeyUp={handleCalculateTripCost} type="number" name="daily_rate" placeholder="Daily Rate..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Per Diem
                </label>
                <Form.Item
                  name={"per_diem"}
                  rules={[
                    { required: false, message: "Please Insert Per Diem !" },
                  ]}
                >
                  <Input onKeyUp={handleCalculateTripCost} type="number" name="per_diem" placeholder="Per Diem..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <div className="trip-cost-wrapper text-center">
                  <h2>${finalTripCost}</h2>
                  <h4>Average Trip Cost</h4>
                </div>
              </Col>

            </Row>

          </div>
          <div className="modal-footer d-flex justify-content-end">
            {currentStep==2?<span><button className="submitbtn" type="button" onClick={handlePrev}>Prev</button><button className="submitbtn" type="submit">Submit</button></span>:
             currentStep==0?<button className="submitbtn" type="button" onClick={handleNext}>Next</button>:<span><button className="submitbtn" type="button" onClick={handlePrev}>Prev</button> <button className="submitbtn" type="button" onClick={handleNext}>Next</button></span>}
          </div>
        </Form>
      </div>


            </Card>
          </Col>
        </Row>
      </div>
      {loading && <DataLoader />}

    </>
  );

}

};

export default CreatJob;
