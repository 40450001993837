import React, { useContext, useEffect } from "react";
import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import { AirCraftContext } from "../../../context/AircraftContext";
import { ManufactureContext } from "../../../context/ManufacturerContext";
import "../styles.scss";
const UpdateAverageRatesModal = ({ handleClose, selectedData, updateData }) => {
  const { getAirCraftsList, aircraftLists } = useContext(AirCraftContext);
  const { getManufacturesList, manufactureLists } =
    useContext(ManufactureContext);
  const [form] = Form.useForm();
  const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    let tempData = {
      manufacturer_id: data.manufacturer_id,
      aircraft_id: data.aircraft_id,
      daily_rate: data.daily_rate,
    };
    updateData(tempData, selectedData.id);
    handleClose();
  };
  useEffect(() => {
    const query = "&manufacturer_id=" + selectedData.manufacturer_id;
    getAirCraftsList(1, false, query);
    getManufacturesList(1, false);
    getAirCraftsList(1, false);
    form.setFieldsValue({
      manufacturer_id: selectedData.manufacturer_id,
      aircraft_id: selectedData.aircraft_id,
      daily_rate: selectedData.daily_rate,
    });
  }, []);
  console.log("selected", selectedData);
  return (
    <Modal
      visible={true}
      title={<span>Update Average Daily Rates</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <label className="title">
              Manufacturer<span style={{ color: "red" }}>*</span>
            </label>
            <Form.Item
              name={"manufacturer_id"}
              rules={[
                { required: true, message: "Please select manufacturer!" },
              ]}
            >
              <Select
                placeholder="Please select a manufacturer!"
                name="manufacturer_id"
                showSearch
                disabled
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {manufactureLists?.map((data, index) => (
                  <Option value={data?.id} key={index}>
                    {data?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <label className="title">
              Aircraft<span style={{ color: "red" }}>*</span>
            </label>
            <Form.Item
              name={"aircraft_id"}
              rules={[{ required: true, message: "Please select aircraft!" }]}
            >
              <Select
                placeholder="Please select aircraft!"
                name="aircraft_id"
                showSearch
                disabled
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {aircraftLists?.map((data, index) => (
                  <Option value={data?.id} key={index}>
                    {data?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <label className="title">Average Daily Rates</label>
            <Form.Item 
              name="daily_rate"
              rules={[
                { required: true, message: "Please input daily rates!" },
              ]}  
            >
              <Input name="daily_rate" type="number" placeholder="Average Daily Rates..." />
            </Form.Item>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdateAverageRatesModal;
