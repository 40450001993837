import React, { createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";
export const UsersContext = createContext();

export const UsersContextProvider = ({ children }) => {
  const [usersList, setUsersList] = useState([]);
  const [allActiveUsersList, setAllActiveUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const getUsersList = async (page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/users?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setUsersList(res?.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getAllActiveUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/users?is_active=true`,
        BEARER_TOKEN_HEADERS
      );
      setAllActiveUsersList(res?.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };


  const deleteUser = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/users/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getUsersList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };
  return (
    <UsersContext.Provider
      value={{
        getUsersList,
        deleteUser,
        loading,
        usersList,
        getAllActiveUsers,
        allActiveUsersList
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
