import ReactApexChart from "react-apexcharts";
// import { Row, Col, Typography } from "antd";
// import pieChart from "./configs/pieChart";
import { Row, Col, Typography } from "antd";


function PieChart({pieChart}) {
const { Title, Paragraph } = Typography;

  return (
    <>
      <div id="chart">
      <Title level={5}>Referral Stats</Title>
        
        <ReactApexChart
          className=""
          options={{
            chart: {
              width: 380,
              type: 'donut',
            },
            labels: pieChart.labels,
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }}
          series={pieChart.values}
          type="donut"
          height={320}
        />
      </div>
    </>
  );
}

export default PieChart;
