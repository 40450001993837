import React, { useState } from "react";
import { Modal, Input } from "antd";
import ImageUploader from "../../imageUploader";
const UpdateCrewTypeModal = ({ handleClose, selectedData, updateData }) => {
  const [name, setName] = useState(selectedData?.name);
  const [icon, setIcon] = useState(selectedData?.icon);
  const [is_active, setIsActive] = useState(selectedData?.is_active);
  const [nameError, setnameError] = useState("");
  const handleAddIcon = (data) => {
    console.log("data", data);
    setIcon(data);
  };
  const handleUpdateData = () => {
    if (name === "") {
      setnameError("This field is required");
    } else {
      updateData({ name, icon, is_active }, selectedData?.id);
      handleClose();
    }
  };

  return (
    <Modal
      visible={true}
      title={<span>Update Crew Type </span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <label className="title">
            Name<span style={{ color: "red" }}>*</span>
          </label>
          <Input
            className="field"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            placeholder="Enter Name Here.."
          />
          <p className="errorMessage">{nameError}</p>
          <label className="title">Icon</label>
          <ImageUploader
            folder="crew_type"
            setImage={handleAddIcon}
            image={icon}
          />
          <p className="errorMessage"></p>
          <label className="checkbox-container">
            Is Activated
            <input
              type="checkbox"
              onChange={() => {
                setIsActive(!is_active);
              }}
              checked={is_active}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            Close
          </button>
          <button className="submit" onClick={handleUpdateData}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateCrewTypeModal;
