import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
// import eChart from "./configs/eChart";

function EChart({eChart}) {
  const { Title, Paragraph } = Typography;

  const items = [
    {
      Title: eChart.totalCrews,
      user: "Crews",
    },
    {
      Title: eChart.totalFAs,
      user: "FAs",
    },
    {
      Title: eChart.totalCaptains,
      user: "Captains",
    },
    {
      Title: eChart.totalOfficers,
      user: "Officers",
    },
  ];

  return (
    <>
      <div id="chart">
      <Title level={5}>Crew Signup Stats</Title>

        <ReactApexChart
          className="bar-chart"
          options={{
            chart: {
              type: "bar",
              width: "100%",
              height: "auto",
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                borderRadius: 5,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 1,
              colors: ["transparent"],
            },
            grid: {
              show: true,
              borderColor: "#ccc",
              strokeDashArray: 2,
            },
            xaxis: {
              categories: eChart.labels,
              labels: {
                show: true,
                align: "right",
                minWidth: 0,
                maxWidth: 160,
                style: {
                  colors: [
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                  ],
                },
              },
            },
            yaxis: {
              labels: {
                show: true,
                align: "right",
                minWidth: 0,
                maxWidth: 160,
                style: {
                  colors: [
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                  ],
                },
              },
            },
        
            tooltip: {
              y: {
                formatter: function (val) {
                  return val;
                },
              },
            },
          }}
          series={[
            {
              name: "Signups",
              data: eChart.values,
              color: "#fff",
            },
          ]}
          type="bar"
          height={220}
        />
      </div>
      <div className="chart-vistior">
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default EChart;
