import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { BannersContext } from "../../context/bannersContext"
import { ConfirmationModal, CreateBannerModal, UpdateBannerModal } from "../../components/Modals/index";
import { getFormattedData } from "../../utils/index";
import DataLoader from "../../components/dataLoader";
const { Title } = Typography;

function Banners() {
  const {
    getBannersList,
    bannersList,
    deleteBanners,
    createBanners,
    updateBanners,
    loading,
  } = useContext(BannersContext);

  const [showCreateBannersModal, setShowCreateBannersModal] =
    useState(false);

  const [showUpdateModal, setShowUpdateModal] =
    useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [page, setPage] = useState(1);
  const { meta, data } = bannersList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };
  useEffect(() => {
    getBannersList(page, true);
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Banner",
      dataIndex: "banner",
      key: "banner",
      render: (data, value) => {
        return (
          <span className="banner-box">
            <img
              src={data}
              alt="icon"
              style={{ maxWidth: "60%", maxHeight: "60%" }}
            />
          </span>
        );
      },
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (data, value) => {
        return <span>{getFormattedData(data)}</span>;
      },
    },
    {
      title: "Banner Type",
      dataIndex: "type",
      key: "type",
      render: (data, value) => {
        return <span>{getFormattedData(data)}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <div className="actions">

            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update Aircraft">
                <EditOutlined />
              </Tooltip>
            </span>

            <span>
              <Tooltip title="Delete Banner">
                <DeleteOutlined onClick={() => handleConfirmModal(record)} />
              </Tooltip>
            </span>


          </div>
        );
      },
    },
  ];

  //HANDLERS
  const handleCreateModal = () => {
    setShowCreateBannersModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateBannersModal(false);
  };

  const handleCreateBanners = (data) => {
    createBanners(data, page, true);
  };


  const handleConfirmModal = (data) => {
    setSelectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteBanners(id, page, true);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const handleUpdateModal = (data) => {
    setSelectedData(data);
    setShowUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };
  const handleUpdateData = (data, id) => {
    updateBanners(data, id, page, true);
  };


  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <button onClick={handleCreateModal}>Create Banners</button>
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>Banners</Title>
                </div>
              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {showCreateBannersModal && (
        <CreateBannerModal
          handleClose={handleCloseCreateModal}
          addBanner={handleCreateBanners}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Banner?"}
          description={"Are you sure you want to delete this Banner?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}

      {showUpdateModal && (
        <UpdateBannerModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateBanner={handleUpdateData}
        />
      )}      
      {loading && <DataLoader />}
    </>
  );
}

export default Banners;
