import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ErrorPage from "../components/404/index.jsx";
// PRIVATE ROUTES
import { AdminPrivateRoute } from "../utils/routing/index";
import {
  AdminDashboard,
  RoleManagement,
  UsersManagement,
  ClientManagement,
  CrewManagement,
  CrewTypeManagement,
  JobsManagement,
  SignIn,
  ManufactureManagement,
  WingTypeManagement,
  AircraftManagement,
  JobTypesManagement,
  SubcriptionPlans,
  CertificationManagement,
  CancellationReason,
  SystemFeedback,
  SystemFeedbackOptions,
  NotificationTypeManagement,
  AirportManagement,
  NotificationManagement,
  AppUpdateManagement,
  JumpSeatManagement
} from "../modules/admin/";
import { Crew, Client, Job, AirCraft, AirPort, CreateJob, CreateJobFullTime, Jumpseat } from "../components/moduleDetail";
import AverageDailyRates from "../modules/admin/averageDailyRates.jsx";
import Banners from "../modules/admin/banners.jsx";

const Routes = () => {
  return (
    <>
      <Router>
        <ToastContainer autoClose={3000} />
        <Switch>
          {/*********** PUBLIC ROUTES **************/}
          <Route exact path="/" component={SignIn} />
          {/*********** ADMIN ROUTES **************/}
          <AdminPrivateRoute
            exact
            path="/admin/dashboard"
            component={AdminDashboard}
          />
          <AdminPrivateRoute
            exact
            path="/admin/trip/:slug/:jobId"
            component={Job}
          />
          <AdminPrivateRoute
            exact
            path="/admin/jumpseat/:slug/:jobId"
            component={Jumpseat}
          />

          <AdminPrivateRoute
            exact
            path="/admin/roles"
            component={RoleManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/users"
            component={UsersManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/clients"
            component={ClientManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/client/:clientId"
            component={Client}
          />
          <AdminPrivateRoute
            exact
            path="/admin/crew-types"
            component={CrewTypeManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/crews"
            component={CrewManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/crew/:crewId"
            component={Crew}
          />
          <AdminPrivateRoute
            exact
            path="/admin/manufacturers"
            component={ManufactureManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/wing-types"
            component={WingTypeManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/aircrafts"
            component={AircraftManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/aircraft/:slug/:aircraftId"
            component={AirCraft}
          />
          <AdminPrivateRoute
            exact
            path="/admin/job-types"
            component={JobTypesManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/trips"
            component={JobsManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/jumpseats"
            component={JumpSeatManagement}
          />

          <AdminPrivateRoute
            exact
            path="/admin/certification-agencies"
            component={CertificationManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/subscriptions"
            component={SubcriptionPlans}
          />
          <AdminPrivateRoute
            exact
            path="/admin/cancellation-reasons"
            component={CancellationReason}
          />
          <AdminPrivateRoute
            exact
            path="/admin/system-feedbacks"
            component={SystemFeedback}
          />
          <AdminPrivateRoute
            exact
            path="/admin/system-feedback-options"
            component={SystemFeedbackOptions}
          />
          <AdminPrivateRoute
            exact
            path="/admin/notification-types"
            component={NotificationTypeManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/airports"
            component={AirportManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/airport/:airportId"
            component={AirPort}
          />
          <AdminPrivateRoute
            exact
            path="/admin/notifications"
            component={NotificationManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/average-daily-rates"
            component={AverageDailyRates}
          />
          <AdminPrivateRoute
            exact
            path="/admin/banners"
            component={Banners}
          />
          <AdminPrivateRoute
            exact
            path="/admin/createJob"
            component={CreateJob}
          />
          <AdminPrivateRoute
            exact
            path="/admin/createJobFullTime"
            component={CreateJobFullTime}
          />
          <AdminPrivateRoute
            exact
            path="/admin/appUpdates"
            component={AppUpdateManagement}
          />

          {/* 404 PAGE ROUTE */}
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Router>
    </>
  );
};
export default Routes;
