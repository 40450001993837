import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState } from "react";
import "../styles.scss";
const CreateAppUpdateModal = ({ handleClose, addAppUpdate }) => {

  const [form] = Form.useForm();


  const handleFormSubmit = () => {
        const data = form.getFieldsValue();
        addAppUpdate(data);
        handleClose();
  };
  return (
    <Modal
      visible={true}
      title={<span>Create AppUpdate </span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
   
      <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
             <Col xs={24} sm={12}>
                <label className="title">
                    Title<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input title!",
                    },
                  ]}
                >
                  <Input name="title" placeholder="Title..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                    App version<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="app_version"
                  rules={[
                    {
                      required: true,
                      message: "Please input app version!",
                    },
                  ]}
                >
                  <Input name="app_version" placeholder="App Version..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                    Build number<span style={{ color: "red" }}></span>
                </label>
                <Form.Item
                  name={"build_number"}
                >
                  <Input name="build_number" placeholder="build number..." />
                </Form.Item>
              </Col>
           
              <Col xs={24} sm={12}>
                <label className="title">
                    Platform<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"platform"}
                  rules={[
                    { required: true, message: "Please select your country!" },
                  ]}
                >
                  <Select placeholder="Please select a platform!" name="platform">
                    <Option value="android" key="android">Android</Option>
                    <Option value="ios" key="ios">Ios</Option>
                    <Option value="web" key="web">Web</Option>
                  </Select>
                </Form.Item>

              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Description<span style={{ color: "red" }}></span>
                </label>
                <Form.Item
                  name={"description"}
                >
                <Input.TextArea
                  rows={5}
                  className="field"
                  name={"description"}
                  onChange={(e) => {
                  }}
                  placeholder="Enter Message Here.."
                />
                </Form.Item>
              </Col>


              <Col xs={24} sm={12}>
                <Form.Item name="force_update" valuePropName="checked">
                  <Checkbox checked={true}>Force update?</Checkbox>
                </Form.Item>
              </Col>

            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>


      </div>
    </Modal>
  );
};

export default CreateAppUpdateModal;
