import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { errorToast } from "../utils";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [loggedInRoles, setLoggedInRoles] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loginLoader, setLoginLoader] = useState(false);
  const handleAdminLogin = async (data) => {
    console.log(data);
    setLoginLoader(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/users/admin_login`,
        data
      );
      setLoggedInRoles(res?.data?.role);
      setCurrentUser(res?.data?.user);
      localStorage.setItem("nvoii_token", res?.data?.token?.token);
      localStorage.setItem("nvoii_role", res?.data?.user?.role_id);
      toast.success("Logged in successfully");
      setTimeout(() => {
        window.location.href = "/admin/dashboard";
      }, 3000);
    } catch (err) {
      setLoginLoader(false);
      errorToast(err);
    }
  };
  const handleAdminLogout = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/users/logout`
      );
      console.log(res?.data);
    } catch (err) {
      errorToast(err);
    }
  };

  const getVerifyCode = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/users/logout`
      );
      console.log(res?.data);
    } catch (err) {
      errorToast(err);
    }
  };
  return (
    <AuthContext.Provider
      value={{
        adminLogin: handleAdminLogin,
        adminLogout: handleAdminLogout,
        getVerifyCode,
        loginLoader,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
