import { Input, Modal } from "antd";
import React, { useState } from "react";
import "../styles.scss";
const CreateCancellationReasonModal = ({ handleClose, onSubmit }) => {
  const [name, setName] = useState("");
  const handleTypeChange = (e) => {
    setName(e.target.value);
  };

  const handleAddData = () => {
    onSubmit(name);
    handleClose();
  };
  return (
    <Modal
      visible={true}
      title={<span>Create Cancellation Reason </span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <label className="title">Reason</label>
          <Input
            className="field"
            onChange={handleTypeChange}
            placeholder="Enter Reason Here.."
          />
        </div>
        <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            Close
          </button>
          <button
            className="submit"
            style={{ opacity: name.length >= 1 ? 1 : 0.3 }}
            disabled={name.length >= 1 ? false : true}
            onClick={handleAddData}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateCancellationReasonModal;
