import React, { useState, createContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";

export const CertificationsContext = createContext();

export const CertificationsContextProvider = ({ children }) => {
  const [certificationsList, setCertificationsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCertificationsList = async (page, paginationStatus, query = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/certification_agencies?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      setLoading(false);
      setCertificationsList(res?.data);
      console.log("Certifications list result", res?.data);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const createCertification = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/certification_agencies`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("crew list result", res?.data);
      setLoading(false);
      getCertificationsList(page, paginationStatus);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateCertification = async (data, id, page, paginationStatus) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/certification_agencies/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      getCertificationsList(page, paginationStatus);
      toast.success(res?.data?.message);
    } catch (err) {
      errorToast(err);
    }
  };

  const deleteCertification = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/certification_agencies/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getCertificationsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <CertificationsContext.Provider
      value={{
        getCertificationsList,
        certificationsList,
        deleteCertification,
        loading,
        updateCertification,
        createCertification,
      }}
    >
      {children}
    </CertificationsContext.Provider>
  );
};
