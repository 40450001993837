import React, { useState, createContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";

export const AverageDailyRatesContext = createContext();

export const AverageDailyRatesContextProvider = ({ children }) => {
  const [averageDailyRatesList, setAverageDailyRatesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAverageDailyRatesList = async (page, paginationStatus, query = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/average_daily_rates?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      setLoading(false);
      setAverageDailyRatesList(res?.data);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const createAverageDailyRates = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/average_daily_rates`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("average daily rate list result", res?.data);
      setLoading(false);
      getAverageDailyRatesList(page, paginationStatus);
      toast.success(res?.data?.message);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateAverageDailyRates = async (data, id, page, paginationStatus) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/average_daily_rates/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      getAverageDailyRatesList(page, paginationStatus);
      toast.success(res?.data?.message);
    } catch (err) {
      errorToast(err);
    }
  };

  const deleteAverageDailyRates = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/average_daily_rates/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAverageDailyRatesList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <AverageDailyRatesContext.Provider
      value={{
        getAverageDailyRatesList,
        averageDailyRatesList,
        deleteAverageDailyRates,
        updateAverageDailyRates,
        createAverageDailyRates,
        loading,
      }}
    >
      {children}
    </AverageDailyRatesContext.Provider>
  );
};
