import React, { createContext, useState } from "react";
import axios from "axios";
import { errorToast } from "../utils";
import { BEARER_TOKEN_HEADERS } from "../utils";
import { toast } from "react-toastify";

export const AirCraftContext = createContext();

export const AirCraftContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [aircraftLists, setAircraftLists] = useState([]);
  const [singleAircraft, setSingleAircraft] = useState(null);
  const [singleaircraftLoader, setSingleAircraftLoader] = useState(true);
  const getAirCraftsList = async (page, paginationStatus, query = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/aircrafts?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        setAircraftLists(res.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err?.response?.data?.message);
      errorToast(err);
    }
  };

  const getSingleAirCraft = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/aircrafts/${id}`,
        BEARER_TOKEN_HEADERS
      );
      setSingleAircraft(res?.data);
      setSingleAircraftLoader(false);
    } catch (err) {
      setSingleAircraftLoader(false);
      errorToast(err);
    }
  };

  const createAirCraft = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/aircrafts`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAirCraftsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const updateAirCraft = async (data, id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/aircrafts/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAirCraftsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  const deleteAirCraft = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/aircrafts/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAirCraftsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  return (
    <AirCraftContext.Provider
      value={{
        getAirCraftsList,
        getSingleAirCraft,
        createAirCraft,
        updateAirCraft,
        deleteAirCraft,
        data: singleAircraft,
        singleaircraftLoader,
        aircraftLists,
        loading,
      }}
    >
      {children}
    </AirCraftContext.Provider>
  );
};
