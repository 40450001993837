import { Input, Modal } from "antd";
import React, { useState } from "react";
import "../styles.scss";
const CreateManufactureModal = ({ handleClose, addType }) => {
  const [name, setName] = useState("");
  const [nameError, setnameError] = useState("");
  const handleAddType = () => {
    if (name === "") {
      setnameError("This field is required");
    } else {
      addType(name);
      handleClose();
    }
  };
  return (
    <Modal
      visible={true}
      title={<span>Create Manufacturer </span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <label className="title">
            Name<span style={{ color: "red" }}>*</span>
          </label>
          <Input
            className="field"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            placeholder="Enter Name Here.."
          />
          <p className="errorMessage">{nameError}</p>
        </div>
        <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            Close
          </button>
          <button className="submit" onClick={handleAddType}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateManufactureModal;
