import React, { createContext, useState } from "react";
import axios from "axios";
import { errorToast } from "../utils";
import { BEARER_TOKEN_HEADERS } from "../utils";
import { toast } from "react-toastify";

export const ManufactureContext = createContext();

export const ManufactureContextProvider = ({ children }) => {
  const [laoding, setlaoding] = useState(false);
  const [manufactureLists, setManufactureLists] = useState([]);
  const getManufacturesList = async (page, paginationStatus, query = "") => {
    setlaoding(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/manufacturers?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        setManufactureLists(res.data);
      }
      setlaoding(false);
    } catch (err) {
      setlaoding(false);
      console.log(err?.response?.data?.message);
      errorToast(err);
    }
  };

  const createManufacture = async (name, page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/manufacturers`,
        { name },
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getManufacturesList(page, paginationStatus);
      setlaoding(false);
    } catch (err) {
      errorToast(err);
      setlaoding(false);
    }
  };

  const updateManufacture = async (name, id, page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/manufacturers/${id}`,
        { name },
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getManufacturesList(page, paginationStatus);
      setlaoding(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setlaoding(false);
    }
  };

  const deleteManufacture = async (id, page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/manufacturers/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getManufacturesList(page, paginationStatus);
      setlaoding(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setlaoding(false);
    }
  };

  return (
    <ManufactureContext.Provider
      value={{
        getManufacturesList,
        createManufacture,
        updateManufacture,
        deleteManufacture,
        manufactureLists,
        laoding,
      }}
    >
      {children}
    </ManufactureContext.Provider>
  );
};
