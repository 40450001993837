import React from "react";
import {
  AuthContextProvider,
  JobsContextProvider,
  ClientContextProvider,
  CrewContextProvider,
  CrewTypeContextProvider,
  RolesContextProvider,
  UsersContextProvider,
  GenericContextProvider,
  ManufactureContextProvider,
  WingTypeContextProvider,
  AirCraftContextProvider,
  JobTypesContextProvider,
  SubscriptionContextProvider,
  CertificationsContextProvider,
  FeedbackContextProvider,
  FeedbackOptionsContextProvider,
  CancellationReasonsContextProvider,
  NotificationTypesContextProvider,
  AirportsContextProvider,
  NotificationsContextProvider,
  AverageDailyRatesContextProvider,
  DashboardContextProvider,
  AppUpdateContextProvider,
} from "../../context";
import { BannersContextProvider } from "../../context/bannersContext";
export default function ContextWrapper({ children }) {
  return (
    <React.StrictMode>
      <AuthContextProvider>
        <ClientContextProvider>
          <CrewTypeContextProvider>
            <CrewContextProvider>
              <JobsContextProvider>
                <RolesContextProvider>
                  <UsersContextProvider>
                    <GenericContextProvider>
                      <ManufactureContextProvider>
                        <WingTypeContextProvider>
                          <AirCraftContextProvider>
                            <JobTypesContextProvider>
                              <SubscriptionContextProvider>
                                <CertificationsContextProvider>
                                  <FeedbackContextProvider>
                                    <FeedbackOptionsContextProvider>
                                      <CancellationReasonsContextProvider>
                                        <NotificationTypesContextProvider>
                                          <AirportsContextProvider>
                                            <NotificationsContextProvider>
                                              <AverageDailyRatesContextProvider>
                                                <BannersContextProvider>
                                                  <DashboardContextProvider>
                                                    <AppUpdateContextProvider>
                                                    {children}
                                                    </AppUpdateContextProvider>
                                                  </DashboardContextProvider>
                                                </BannersContextProvider>
                                              </AverageDailyRatesContextProvider>
                                            </NotificationsContextProvider>
                                          </AirportsContextProvider>
                                        </NotificationTypesContextProvider>
                                      </CancellationReasonsContextProvider>
                                    </FeedbackOptionsContextProvider>
                                  </FeedbackContextProvider>
                                </CertificationsContextProvider>
                              </SubscriptionContextProvider>
                            </JobTypesContextProvider>
                          </AirCraftContextProvider>
                        </WingTypeContextProvider>
                      </ManufactureContextProvider>
                    </GenericContextProvider>
                  </UsersContextProvider>
                </RolesContextProvider>
              </JobsContextProvider>
            </CrewContextProvider>
          </CrewTypeContextProvider>
        </ClientContextProvider>
      </AuthContextProvider>
    </React.StrictMode>
  );
}
