import React, { useState, createContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";

export const CancellationReasonsContext = createContext();

export const CancellationReasonsContextProvider = ({ children }) => {
  const [cancellationReasonsList, setCancellationReasonsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCancellationReasonsList = async (
    page,
    paginationStatus,
    query = ""
  ) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/cancellation_reasons?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      setLoading(false);
      setCancellationReasonsList(res?.data);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const createCancellationReason = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/cancellation_reasons`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("crew list result", res?.data);
      setLoading(false);
      getCancellationReasonsList(page, paginationStatus);
      toast.success(res?.data?.message);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateCancellationReason = async (data, id, page, paginationStatus) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/cancellation_reasons/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      getCancellationReasonsList(page, paginationStatus);
      toast.success(res?.data?.message);
    } catch (err) {
      errorToast(err);
    }
  };

  const deleteCancellationReason = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/cancellation_reasons/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getCancellationReasonsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <CancellationReasonsContext.Provider
      value={{
        getCancellationReasonsList,
        cancellationReasonsList,
        deleteCancellationReason,
        updateCancellationReason,
        createCancellationReason,
        loading,
      }}
    >
      {children}
    </CancellationReasonsContext.Provider>
  );
};
