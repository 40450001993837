import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import "../styles.scss";
const UpdateAppUpdateModal = ({ handleClose, selectedData , updateAppUpdate }) => {

  const [form] = Form.useForm();

  useEffect(() => {
    console.log("Selected Add Update",selectedData)
    form.setFieldsValue({
        title: selectedData.title,
        app_version:selectedData.app_version,
        build_number:selectedData.build_number,
        platform:selectedData.platform,
        description:selectedData.description,
        force_update:selectedData.force_update
      });
  
}, []);


const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    let tempData = {
        title: data.title,
        app_version:data.app_version,
        build_number:data.build_number,
        platform:data.platform,
        description:data.description,
        force_update:data.force_update ? data.force_update : false
    }
    updateAppUpdate(tempData , selectedData.id);
    handleClose();
};

  return (
    <Modal
      visible={true}
      title={<span>Update AppUpdate </span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
   
      <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
             <Col xs={24} sm={12}>
                <label className="title">
                    Title<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input title!",
                    },
                  ]}
                >
                  <Input name="title" placeholder="Title..." readOnly />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                    App version<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="app_version"
                  rules={[
                    {
                      required: true,
                      message: "Please input app version!",
                    },
                  ]}
                >
                  <Input name="app_version" placeholder="App Version..."readOnly />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                    Build number<span style={{ color: "red" }}></span>
                </label>
                <Form.Item
                  name={"build_number"}
                >
                  <Input name="build_number" placeholder="build number..."readOnly />
                </Form.Item>
              </Col>
           
              <Col xs={24} sm={12}>
                <label className="title">
                    Platform<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"platform"}
                  rules={[
                    { required: true, message: "Please select your country!" },
                  ]}
                >
                  <Select placeholder="Please select a platform!" name="platform" disabled>
                    <Option value="android" key="android">Android</Option>
                    <Option value="ios" key="ios">Ios</Option>
                    <Option value="web" key="web">Web</Option>
                  </Select>
                </Form.Item>

              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Description<span style={{ color: "red" }}></span>
                </label>
                <Form.Item
                  name={"description"}
                >
                <Input.TextArea
                  rows={5}
                  className="field"
                  name={"description"}
                  onChange={(e) => {
                  }}
                  placeholder="Enter Message Here.."
                />
                </Form.Item>
              </Col>


              <Col xs={24} sm={12}>
                <Form.Item name="force_update" valuePropName="checked">
                  <Checkbox checked={true}>Force update?</Checkbox>
                </Form.Item>
              </Col>

            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>


      </div>
    </Modal>
  );
};

export default UpdateAppUpdateModal;
