import React, { useState, createContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";

export const DashboardContext = createContext();

export const DashboardContextProvider = ({ children }) => {
    const [dashboardStats, setDashboardStats] = useState(null);
    const [loading, setLoading] = useState(false);

    const getDashboardStats = async () => {
        setLoading(true);
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_ENV}/reports/dashboard`,
            BEARER_TOKEN_HEADERS
          );
          setLoading(false);
          setDashboardStats(res?.data);
          console.log("Dashboard Stats", res?.data);
        } catch (err) {
          setLoading(false);
          errorToast(err);
          if(err?.response?.status == 401){
            localStorage.removeItem("nvoii_token");
            location.reload();        
          }
        }
      };
    


    return (
      <DashboardContext.Provider
        value={{
          dashboardStats,
          getDashboardStats
        }}
      >
        {children}
      </DashboardContext.Provider>
    );
  };
  