import React, { createContext, useState } from "react";
import axios from "axios";
import {
  errorToast,
  BEARER_TOKEN_HEADERS,
  BEARER_TOKEN_IMAGE_HEADERS,
} from "../utils";
import { toast } from "react-toastify";

export const GenericContext = createContext();

export const GenericContextProvider = ({ children }) => {
  const [countries, setCountries] = useState([]);
  const [flightHours, setFlightHours] = useState([]);
  const [usStates, setUsStates] = useState([]);
  const [states, setStates] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [workplaceTypes, setWorkplaceTypes] = useState([]);
  const [positionTypes, setPositionTypes] = useState([]);
  const [flightExperiences, setFlightExperiences] = useState([]);


  const getCountries = async (page, paginationStatus) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/countries?page=1&paginate=true&limit=250`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        setCountries(res.data.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };


  const getFlightHours = async (page, paginationStatus) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/flight_hours`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        console.log("Flight Hours",res?.data);
        setFlightHours(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const getUsStates = async (page, paginationStatus) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/states?country_id=233`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        console.log("US States",res?.data);
        setUsStates(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const getStates = async (country_id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/states?country_id=${country_id}`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        console.log("States",res?.data);
        setStates(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const getPaymentTerms = async (page, paginationStatus) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/payment_terms`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        console.log("Payment Terms",res?.data);
        setPaymentTerms(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const uploadImage = async (folder, image, responseCallback) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/uploader/upload_single/${folder}`,
        image,
        BEARER_TOKEN_IMAGE_HEADERS
      );
      if (res.data) {
        responseCallback(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const getWorkplaceTypes = async (page, paginationStatus) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/workplace_types`,
        BEARER_TOKEN_HEADERS
    );
      if (res?.data) {
        console.log("Workplace Types", res?.data);
        setWorkplaceTypes(res.data);
      }
  } catch (err) {
      errorToast(err);
  }
  };

  const getPositionTypes = async (page, paginationStatus) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/position_types`,
        BEARER_TOKEN_HEADERS
    );
      if (res?.data) {
        console.log("Position Types", res?.data);
        setPositionTypes(res.data);
      }
  } catch (err) {
      errorToast(err);
  }
  };

  const getFlightExperiences = async (page, paginationStatus) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/flight_experiences`,
        BEARER_TOKEN_HEADERS
    );
      if (res?.data) {
        console.log("Flight Expereinces", res?.data);
        setFlightExperiences(res.data);
      }
  } catch (err) {
      errorToast(err);
  }
  };



  return (
    <GenericContext.Provider
      value={{
        getCountries,
        countries,
        uploadImage,
        getFlightHours,
        flightHours,
        getUsStates,
        usStates,
        getPaymentTerms,
        paymentTerms,
        getWorkplaceTypes,
        workplaceTypes,
        getPositionTypes,
        positionTypes,
        getFlightExperiences,
        flightExperiences,
        getStates,
        states
      }}
    >
      {children}
    </GenericContext.Provider>
  );
};
