import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Button, Tooltip, Form, Input } from "antd";
import { FilterFilled,  EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { AirportsContext } from "../../context/AirportContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import DataLoader from "../../components/dataLoader";
import FilterDrawer from "../../components/filterDrawer";
import { UpdateAirporttModal,ConfirmationModal } from "../../components/Modals/index";

function AirportManagement() {
  const { getAirportsList, airportsList, loading, updateAirPort, deleteAirPort } =
    useContext(AirportsContext);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [page, setPage] = useState(1);

  const { meta, data } = airportsList;
  const { Title, Paragraph } = Typography;

  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };
  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    setFilterOpen(false);
    setFilteredData(credentials);
  };
  const handleResetForm = () => {
    form.resetFields();
    setFilteredData(null);
    form.setFieldsValue({
      q: undefined,
    });
    setFilterOpen(false);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  useEffect(() => {
    let filterQuery = "";
    if (filteredData) {
      Object.keys(filteredData).forEach(function (key) {
        if (filteredData[key] && key !== "date") {
          filterQuery += `&${key}=${filteredData[key]}`;
        }
      });
    }
    getAirportsList(page, true, filterQuery);
  }, [page, filteredData]);

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, value) => <span>{data}</span>,
    },
    {
      title: "ICAO",
      dataIndex: "icao",
      key: "icao",
      render: (data, value) => <span>{data}</span>,
    },
    {
      title: "IATA",
      dataIndex: "iata",
      key: "iata",
      render: (data, value) => <span>{data ?? "-"}</span>,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (data, value) => <span>{data}</span>,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (data, value) => <span>{data}</span>,
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (data, value) => <span>{data ? "Yes" : "No"}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const { slug, id } = record;
        return (
          <div className="actions">
            <Link
              className="preview"
              to={`airport/${id}`}
              style={{ marginLeft: 15 }}
            >
              <Tooltip title="View Airport">
                <FontAwesomeIcon icon={faEye} />
              </Tooltip>
            </Link>
            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update Airport">
                <EditOutlined />
              </Tooltip>
            </span>
            <span>
              <Tooltip title="Delete Airport">
                <DeleteOutlined onClick={() => handleConfirmModal(record)} />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  const drawerSec = () => (
    <>
      {isFilterOpen && (
        <Form
          form={form}
          className="form"
          initialValues={filteredData}
          onFinish={handleFormFinish}
        >
          <label className="form-lbl">Search Airport:</label>
          <Form.Item name={"q"}>
            <Input type="text" />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              className="form-btn"
              style={{ minWidth: "100%" }}
            >
              Apply Filters
            </Button>
            <span className="form-rm-btn" onClick={handleResetForm}>
              Reset Filters
            </span>
          </Form.Item>
        </Form>
      )}
    </>
  );

  //HANDLERS

  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };
  const handleUpdateData = (data, id) => {
    updateAirPort(data, id, page, true);
  };


  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteAirPort(id, page, true);
  };


  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <Title level={5}>Airports</Title>
                <Button className="filter-btn" onClick={filterHandler}>
                  <FilterFilled />
                  Filter
                </Button>
              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />

      {showUpdateModal && (
        <UpdateAirporttModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Airport?"}
          description={"Are you sure you want to delete this airport?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}

      {loading && <DataLoader />}
    </>
  );
}

export default AirportManagement;
