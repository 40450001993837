import React, { createContext, useState } from "react";
import axios from "axios";
import { errorToast } from "../utils";
import { BEARER_TOKEN_HEADERS } from "../utils";
import { toast } from "react-toastify";

export const SubscriptionContext = createContext();

export const SubscriptionContextProvider = ({ children }) => {
  const [laoding, setlaoding] = useState(false);
  const [subscriptionLists, setSubscriptionLists] = useState([]);
  const getSubscriptionsList = async (page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/subscription_plans?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        setSubscriptionLists(res.data);
      }
      setlaoding(false);
    } catch (err) {
      setlaoding(false);
      console.log(err?.response?.data?.message);
      errorToast(err);
    }
  };

  const updateSubscription = async (name, id, page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/subscription_plans/${id}`,
        name,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getSubscriptionsList(page, paginationStatus);
      setlaoding(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setlaoding(false);
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        getSubscriptionsList,
        updateSubscription,
        subscriptionLists,
        laoding,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
