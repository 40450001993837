import { Input, Modal, Select } from "antd";
import React, { useState,useEffect } from "react";
import "../styles.scss";
import {
  NOTIFICATION_USER_TYPE,
  NOTIFICATION_MEDIUM_TYPE,
  NOTIFICATION_PLATFORM_TYPE,
} from "../../../constants";
const CreateNotificationModal = ({ handleClose, addType, usersList, crewTypeList }) => {

  console.log(crewTypeList);
  // let userIds = "";
  const [user_ids, setUser_ids] = useState("");
  const [crewTypeIds, setCrewTypeIds] = useState("");
  const [crewTypeError, setCrewTypeError] = useState("");

  const [usersError, setUsersError] = useState("");

  const [sendInBulk, setSendInBulk] = useState(false);


  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [user_type, setUser_type] = useState("");
  const [typeError, setTypeError] = useState("");
  const [platform, setPlatform] = useState("");
  const [platformError, setPlatformError] = useState("");
  const [medium, setMedium] = useState("");
  const [mediumError, setMediumError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  
  
  const handleAddType = () => {

    if(sendInBulk == true){

      if (user_type === "") {
        setTypeError("User type is required");
      }
      if (platform === "") {
        setPlatformError("Platform is required");
      }
      setUser_ids("");
    }else{

      if (user_ids === "") {
        setUsersError("Users is required");
      }
      setUser_type("");
      setPlatform("both");
    }

    if (title === "") {
      setTitleError("Title is required");
    }
    if (medium === "") {
      setMediumError("Medium is required");
    }
    if (message === "") {
      setMessageError("Message is required");
    } else {
      addType({ title, user_type, platform, medium, message, user_ids,crew_type_id:crewTypeIds});
      handleClose();
    }
  };

  useEffect(() => {
    console.log("Updated user_ids:", user_ids);
  }, [user_ids]);

  return (
    <Modal
      visible={true}
      title={<span>Create Notification</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div style={sendInBulk==true?{display:"none"}:{}}>
          <label className="title">
            User<span style={{ color: "red" }}>*</span>
          </label>
          <Select
            mode="multiple"
            placeholder="Select user"
            className="field"
            onChange={(selectedValues) => {
              var selectedUserIds = selectedValues.join(",");
              setUser_ids(selectedUserIds);
            }}
            allowClear
            showSearch
            filterOption={(inputValue, option) =>
              option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
            }
          >
            {usersList.map((data) => (
              <Select.Option value={data.id} key={data.id}>
                {data.firstname} {data.lastname} 
              </Select.Option>
            ))}
          </Select>
          <p className="errorMessage">{usersError}</p>
          </div>
          <label className="checkbox-container">
            Send In Bulk
            <input
              type="checkbox"
              onChange={() => {
                setSendInBulk(!sendInBulk);
              }}
              checked={sendInBulk}
            />
            <span className="checkmark"></span>
          </label>
          <p className="errorMessage"></p>

          <div style={sendInBulk==false?{display:"none"}:{}}>    
          <label className="title">
            User type<span style={{ color: "red" }}>*</span>
          </label>
          <Select
            placeholder="Select user type"
            className="field"
            onChange={(e) => {
              console.log(e);
              setUser_type(e);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {NOTIFICATION_USER_TYPE.map((data, index) => (
              <Select.Option value={data.id} key={index}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
          <p className="errorMessage">{typeError}</p>
          
          {user_type=='crew'?<div><label className="title">
            Crew type<span style={{ color: "red" }}></span>
          </label>
          <Select
            mode="multiple"
            placeholder="Select Crew Type"
            className="field"
            onChange={(selectedValues) => {
              var selectedCrewTypeIds = selectedValues.join(",");
              setCrewTypeIds(selectedCrewTypeIds);
            }}
            allowClear
            showSearch
            filterOption={(inputValue, option) =>
              option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
            }
          >
            {crewTypeList.map((data) => (
              <Select.Option value={data.id} key={data.id}>
                {data.name} 
              </Select.Option>
            ))}
          </Select>
          <p className="errorMessage">{crewTypeError}</p> </div>:""}         
          
          
          <label className="title">
            Platform<span style={{ color: "red" }}>*</span>
          </label>
          <Select
            placeholder="Select platform"
            className="field"
            onChange={(e) => {
              setPlatform(e);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {NOTIFICATION_PLATFORM_TYPE.map((data, index) => (
              <Select.Option value={data.id} key={index}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
          <p className="errorMessage">{platformError}</p>
          </div>
         
         
          <label className="title">
            Medium<span style={{ color: "red" }}>*</span>
          </label>
          <Select
            placeholder="Select medium"
            className="field"
            onChange={(e) => {
              setMedium(e);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {NOTIFICATION_MEDIUM_TYPE.map((data, index) => (
              <Select.Option value={data.id} key={index}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
          <p className="errorMessage">{mediumError}</p>
          
          <label className="title">
            Title<span style={{ color: "red" }}>*</span>
          </label>
          <Input
            className="field"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            placeholder="Enter Title Here.."
          />
          <p className="errorMessage">{titleError}</p>          
          <label className="title">
            Message<span style={{ color: "red" }}>*</span>
          </label>
          <Input.TextArea
            className="field"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            value={message}
            placeholder="Enter Message Here.."
          />
          <p className="errorMessage">{messageError}</p>
        </div>
        <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            Close
          </button>
          <button className="submit" onClick={handleAddType}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateNotificationModal;
