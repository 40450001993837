import React, { createContext, useState } from "react";
import axios from "axios";
import { errorToast } from "../utils";
import { BEARER_TOKEN_HEADERS } from "../utils";
import { toast } from "react-toastify";

export const CrewTypeContext = createContext();

export const CrewTypeContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [crewLists, setCrewLists] = useState([]);
  const [crewTypeLists, setCrewTypeLists] = useState([]);
  const getCrewTypesList = async (page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/crew_types?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        console.log('crew type list',res.data);
        setCrewLists(res.data);
        setCrewTypeLists(res.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err?.response?.data?.message);
      errorToast(err);
    }
  };

  const getSingleCrewType = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/crew_types/${id}`,
        BEARER_TOKEN_HEADERS
      );
      console.log("single crew  result", res?.data);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
    }
  };

  const createCrewType = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/crew_types`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getCrewTypesList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const updateCrewType = async (data, id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/crew_types/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getCrewTypesList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  const deleteCrewType = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/crew_types/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getCrewTypesList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  return (
    <CrewTypeContext.Provider
      value={{
        getCrewTypesList,
        getSingleCrewType,
        createCrewType,
        updateCrewType,
        deleteCrewType,
        crewLists,
        crewTypeLists,
        loading,
      }}
    >
      {children}
    </CrewTypeContext.Provider>
  );
};
