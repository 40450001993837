import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Form, Input, Button, Tooltip } from "antd";
import { FilterFilled, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { ManufactureContext } from "../../context/ManufacturerContext";
import {
  CreateManufactureModal,
  UpdateManufactureModal,
  ConfirmationModal,
} from "../../components/Modals/index";
import DataLoader from "../../components/dataLoader";
import { getFormattedDateTime, getFormattedData } from "../../utils";
import FilterDrawer from "../../components/filterDrawer";
const { Title } = Typography;

function ManufactureManagement() {
  const {
    getManufacturesList,
    createManufacture,
    updateManufacture,
    deleteManufacture,
    manufactureLists,
    laoding,
  } = useContext(ManufactureContext);
  const [showcreateManufactureModal, setShowcreateManufactureModal] =
    useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [page, setPage] = useState(1);
  const { meta, data } = manufactureLists;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
    showSizeChanger: false,
  };
  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    setFilterOpen(false);
    setFilteredData(credentials);
  };
  const handleResetForm = () => {
    form.resetFields();
    setFilteredData(null);
    form.setFieldsValue({
      name: undefined,
    });
    setFilterOpen(false);
  };

  useEffect(() => {
    let filterQuery = "";
    if (filteredData) {
      Object.keys(filteredData).forEach(function (key) {
        if (filteredData[key] && key !== "date") {
          filterQuery += `&${key}=${filteredData[key]}`;
        }
      });
    }
    getManufacturesList(page, true, filterQuery);
  }, [page, filteredData]);

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, value) => {
        return <span>{getFormattedData(data)}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <div className="actions">
            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update Manufacturer">
                <EditOutlined />
              </Tooltip>
            </span>
            <span>
              <Tooltip title="Delete Manufacturer">
                <DeleteOutlined onClick={() => handleConfirmModal(record)} />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  const drawerSec = () => (
    <>
      {isFilterOpen && (
        <Form
          form={form}
          className="form"
          initialValues={filteredData}
          onFinish={handleFormFinish}
        >
          <label className="form-lbl">Name:</label>
          <Form.Item name={"name"}>
            <Input type="text" />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              className="form-btn"
              style={{ minWidth: "100%" }}
            >
              Apply Filters
            </Button>
            <span className="form-rm-btn" onClick={handleResetForm}>
              Reset Filters
            </span>
          </Form.Item>
        </Form>
      )}
    </>
  );

  //HANDLERS

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  const handleCreateModal = () => {
    setShowcreateManufactureModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowcreateManufactureModal(false);
  };

  const handlecreateManufacture = (name) => {
    createManufacture(name, page, true);
  };
  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };
  const handleUpdateData = (name, id) => {
    updateManufacture(name, id, page, true);
  };

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteManufacture(id, page, true);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <button onClick={handleCreateModal}>Create Manufacturer</button>
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <Title level={5}>Manufacturer</Title>
                <Button className="filter-btn" onClick={filterHandler}>
                  <FilterFilled />
                  Filter
                </Button>
              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {showcreateManufactureModal && (
        <CreateManufactureModal
          handleClose={handleCloseCreateModal}
          addType={handlecreateManufacture}
        />
      )}
      {showUpdateModal && (
        <UpdateManufactureModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Manufacturer?"}
          description={"Are you sure you want to delete this manufacturer?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
      {laoding && <DataLoader />}
    </>
  );
}

export default ManufactureManagement;
