import React, { useState, createContext } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const AirportsContext = createContext();

export const AirportsContextProvider = ({ children }) => {
  const [airportsList, setAirportsList] = useState([]);
  const [airportsListAll, setAirportsListAll] = useState([]);
  const [singleAirport, setSingleAirport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [singleAirportLoader, setSingleAirportLoader] = useState(false);
  const getAirportsList = async (page, paginationStatus, query = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/airports?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      setLoading(false);
      setAirportsList(res?.data);
      console.log("Airports list result", res?.data);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getSingleAirport = async (id) => {
    setSingleAirportLoader(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/airports/${id}`,
        BEARER_TOKEN_HEADERS
      );
      console.log("crew list result", res?.data);
      setSingleAirport(res?.data);
      setSingleAirportLoader(false);
    } catch (err) {
      setSingleAirportLoader(false);
      errorToast(err);
    }
  };


  const updateAirPort = async (data, id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/airports/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAirportsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  const deleteAirPort = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/airports/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAirportsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  const getAllAirports = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/airports`,
        BEARER_TOKEN_HEADERS
      );
      setLoading(false);
      setAirportsListAll(res?.data);
      console.log("All Airports list result", res?.data);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };


  return (
    <AirportsContext.Provider
      value={{
        getAirportsList,
        getSingleAirport,
        updateAirPort,
        deleteAirPort,
        getAllAirports,
        airportsList,
        airportsListAll,
        data: singleAirport,
        singleAirport,
        singleAirportLoader,
        loading,
      }}
    >
      {children}
    </AirportsContext.Provider>
  );
};
