import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Tooltip } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { WingTypeContext } from "../../context/WingTypeContext";
import {
  CreateWingTypeModal,
  UpdateWingTypeModal,
  ConfirmationModal,
} from "../../components/Modals/index";
import DataLoader from "../../components/dataLoader";
import { getFormattedDateTime, getFormattedData } from "../../utils";
const { Title } = Typography;

function WingTypeManagement() {
  const {
    getWingTypesList,
    createWingType,
    updateWingType,
    deleteWingType,
    wingTypeLists,
    laoding,
  } = useContext(WingTypeContext);
  const [showcreateWingTypeModal, setShowcreateWingTypeModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [page, setPage] = useState(1);
  const { meta, data } = wingTypeLists;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  useEffect(() => {
    getWingTypesList(page, true);
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, value) => {
        return <span>{getFormattedData(data)}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <div className="actions">
            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update Wing Type">
                <EditOutlined />
              </Tooltip>
            </span>
            <span>
              <Tooltip title="Delete Wing Type">
                <DeleteOutlined onClick={() => handleConfirmModal(record)} />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  //HANDLERS
  const handleCreateModal = () => {
    console.log("sssss");
    setShowcreateWingTypeModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowcreateWingTypeModal(false);
  };

  const handlecreateWingType = (name) => {
    createWingType(name, page, true);
  };
  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };
  const handleUpdateData = (name, id) => {
    updateWingType(name, id, page, true);
  };

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteWingType(id, page, true);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <button onClick={handleCreateModal}>Create WingType</button>
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>Wing Type</Title>
                  <Paragraph className="lastweek"></Paragraph>
                </div>
              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {showcreateWingTypeModal && (
        <CreateWingTypeModal
          handleClose={handleCloseCreateModal}
          addType={handlecreateWingType}
        />
      )}
      {showUpdateModal && (
        <UpdateWingTypeModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete WingType?"}
          description={"Are you sure you want to delete this WingType?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}
      {laoding && <DataLoader />}
    </>
  );
}

export default WingTypeManagement;
