import React, { useEffect } from "react";
import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import "../styles.scss";
const UpdateSubscriptionModal = ({ handleClose, selectedData, updateData }) => {
  const unitList = [
    {
      id: "d",
      name: "Day",
    },
    {
      id: "mo",
      name: "Month",
    },
    {
      id: "y",
      name: "Year",
    },
  ];
  const [form] = Form.useForm();
  const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    updateData(data, selectedData.id);
    handleClose();
  };
  useEffect(() => {
    form.setFieldsValue({
      unit_abbr: selectedData.unit_abbr,
      quantity: selectedData.quantity,
      price: selectedData.price,
      save_amount: selectedData.save_amount,
      total: selectedData.total,
      is_popular: selectedData.is_popular,
    });
  }, []);
  console.log("selected", selectedData);
  return (
    <Modal
      visible={true}
      title={<span>Update Subscription Plan</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <label className="title">
              Quantity<span style={{ color: "red" }}>*</span>
            </label>
            <Form.Item
              name="quantity"
              rules={[
                {
                  required: true,
                  message: "Please input quantity!",
                },
              ]}
            >
              <Input type="number" name="quantity" placeholder="Quantity..." />
            </Form.Item>
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <label className="title">Unit</label>
                <Form.Item name="unit_abbr">
                  <Select
                    placeholder="Select a manufacturer!"
                    name="unit_abbr"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {unitList?.map((data, index) => (
                      <Option value={data?.id} key={index}>
                        {data?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">Price</label>
                <Form.Item name="price">
                  <Input type="number" name="price" placeholder="Price..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">Save Amount</label>
                <Form.Item name="save_amount">
                  <Input
                    type="number"
                    name="save_amount"
                    placeholder="Save Amount..."
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">Total Amount</label>
                <Form.Item name="total">
                  <Input
                    type="number"
                    name="total"
                    placeholder="Total Amount..."
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="is_popular" valuePropName="checked">
              <Checkbox checked={false} value={false}>
                Is Popular?
              </Checkbox>
            </Form.Item>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdateSubscriptionModal;
