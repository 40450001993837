import React, { useEffect, useContext, useState } from "react";
import { useParams,Link } from "react-router-dom";
import Loader from "../../Loader/index";
import { Row, Col, Card, Typography, Collapse, Tabs } from "antd";
import { JobsContext } from "../../../context/JobsContext";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import AdminDataTable from "../../Layouts/sharedAdminLayout/partials/data-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFormattedDate, getFormattedDateTime } from "../../../utils";
import moment from "moment";
import { NotificationsContext } from "../../../context/notificationContext";
import { ConfirmationModal } from "../../Modals";
import { getFormattedData } from "../../../utils";


const Job = () => {
  const { TabPane } = Tabs;
  const { slug, jobId } = useParams();
  const { Title, Paragraph } = Typography;
  const { getSingleJob, singleJob, singleJobLoader } = useContext(JobsContext);
  const { resendNotification, getNotificationsList, notificationsList, loading } = useContext(NotificationsContext);
  const [page, setPage] = useState(1);

  const { meta, data} = notificationsList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };


  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);


  useEffect(() => {
    let query = `&notification_type_id=1&job_id=${jobId}`;
    getNotificationsList(page, true, query);
  }, [page]);


  useEffect(() => {
    getSingleJob(jobId);
  }, []);
  console.log(singleJobLoader);


  const handleConfirmModal = () => {
    console.log(singleJob);
    setselectedData(singleJob);

    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleResendNotification = (id) => {
    resendNotification(id);
  };
  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };



  if (singleJobLoader) {
    console.log(singleJobLoader);
    return <Loader />;
  } else {
    const tab1Sec = () => (
      <Row gutter={[24, 0]}>

        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Client</h5>
          <Link to={`/admin/client/${singleJob?.client_id}`} className="preview">

            <p className="detail-description">

              <img
                src={singleJob?.client?.user?.photo_thumb}
                alt="Large Avatar"
                style={{ width: "50px", height: "50px", borderRadius:"50%", marginRight:"10px" }}
              />

              {singleJob?.client?.user?.firstname} {singleJob?.client?.user?.lastname} 
              </p>
          </Link> 
        </Col>


        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Route</h5>
          <p className="detail-description">
            {singleJob?.airports.map((x, i) => {
              return i == 0 ? x.name : ` - ${x.name}`;
            })}
          </p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">From</h5>
          <p className="detail-description">
            {getFormattedDateTime(singleJob?.detail?.date_from)}
          </p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">To</h5>
          <p className="detail-description">
            {getFormattedDateTime(singleJob?.detail?.date_to)}
          </p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Duration</h5>
          <p className="detail-description">
            {moment(singleJob?.detail?.date_to).diff(
              moment(singleJob?.detail?.date_from),
              "days"
            )}{" "}
            days
          </p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Aircraft Make</h5>
          <p className="detail-description">{singleJob?.manufacturer?.name}</p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Aircraft Model</h5>
          <p className="detail-description">{singleJob?.aircraft?.name}</p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Min hours on type</h5>
          <p className="detail-description">
            {singleJob?.min_flight_hours_on_type?.name}
          </p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Min flight hours</h5>
          <p className="detail-description">
            {singleJob?.min_flight_hours?.name}
          </p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Crew type</h5>
          <p className="detail-description">{singleJob?.crew_type?.name}</p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">States</h5>
          <p className="detail-description">
            {singleJob?.states?.map((x, i) => {
              return i == 0 ? x.name : `, ${x.name}`;
            })}
          </p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Countries</h5>
          <p className="detail-description">
            {singleJob?.countries && singleJob?.countries.length >= 1
              ? singleJob?.countries?.map((x, i) => {
                  return i == 0 ? x.name : `, ${x.name}`;
                })
              : "N/A"}
          </p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Daily Rate</h5>
          <p className="detail-description">${singleJob?.detail?.daily_rate}</p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Per Diem</h5>
          <p className="detail-description">${singleJob?.detail?.per_diem}</p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Paid Lodging</h5>
          <p className="detail-description">
            {singleJob?.detail?.paid_lodging === true ? "Yes" : "No"}
          </p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Payment Term</h5>
          <p className="detail-description">
            {singleJob?.detail?.payment_term?.label}
          </p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Job Status</h5>
          <p className="detail-description">{singleJob?.job_status?.name}</p>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <h5 className="detail-title">Created at</h5>
          <p className="detail-description">{getFormattedDateTime(singleJob?.created_at)}</p>
        </Col>


      </Row>
    );
    const tab2sec = () => {
      const columns = [
        {
          title: "",
          dataIndex: "",
          key: "No",
          render: (data, value, i) => (
            <b
              style={{
                color: "#ffe81f",
                display: "block",
                textAlign: "center",
              }}
            >
              {data.is_accepted && data.is_awarded ? (
                <FontAwesomeIcon icon={faStar} />
              ) : (
                ""
              )}
            </b>
          ),
        },
        {
          title: "Name",
          dataIndex: "crew",
          key: "crew",
          render: (data, value) => (
            <span>
              <Link target="_blank" to={`/admin/crew/${data?.id}`} className="preview" style={{color:"rgba(0, 0, 0, 0.85)"}}>
              <img src={data?.user?.photo_thumb} alt="" style={{ height: "50px", width: "50px",marginRight:"10px",borderRadius:"50%"}}/>
              {data?.user?.firstname} {data?.user?.lastname}
              </Link>
            </span>
          ),
        },
        {
          title: "Rating",
          dataIndex: "crew",
          key: "rating",
          render: (data, value) => <span>{data?.rating}</span>,
        },
      ];
      return <AdminDataTable columns={columns} data={singleJob?.applicants} />;
    };
    const tab3Sec = () => {
      const starArr = [1, 2, 3, 4, 5];
      return (
        <Row gutter={[24, 0]} className="rating-wrapper">
          {singleJob?.ratings.map((item, index) => (
            <Col xs={24} sm={24} md={12} key={index}>
              <div className="rating-box">
                <div className="rating">
                  {starArr.map((x) => (
                    <FontAwesomeIcon
                      key={x}
                      style={{
                        color: item.rating >= x ? "#ffe81f" : "#eeeeee",
                        marginRight: 5,
                      }}
                      icon={faStar}
                    />
                  ))}
                </div>
                <h4>{item.author}</h4>
                <p>{item.comment}</p>
              </div>
            </Col>
          ))}
        </Row>
      );
    };

    const tab4sec = () => {
      const columns = [
        {
          title: "No",
          dataIndex: "No",
          key: "No",
          render: (data, value, i) => (
            <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
          ),
        },
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          render: (data, value) => {
            return <span>{getFormattedData(data)}</span>;
          },
        },
        {
          title: "Type",
          dataIndex: "notification_type",
          key: "notification_type",
          render: (data, value) => {
            return <span>{getFormattedData(data?.name)}</span>;
          },
        },
        {
          title: "User",
          dataIndex: "user",
          key: "user",
          render: (data, value) => {
            return <span>{`${getFormattedData(data?.firstname)} ${getFormattedData(data?.lastname)} ( ${getFormattedData(data?.email)} )`}</span>;
          },
        },

      ];
      return (
        <>
          <p style={{textAlign:'right'}}>Total Notifications Sent: <strong style={{fontWeight:"bold"}}>{meta?.total}</strong></p>
          <AdminDataTable data={data} columns={columns} pagination={{ ...paginationConfig, onChange: handlePageChange }} expandable={false}/>
        </>
      );
    };


    const noData = (message) => <p className="no-data">{message}</p>;
    return (
      <div className="layout-content">
        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <Row>
              <Col xs={24} sm={24} md={12}>
                <Title level={5}>{singleJob?.title}</Title>
                <Paragraph className="lastweek" style={{ marginBottom: 5 }}>
                  {singleJob?.description}
                </Paragraph>              
              </Col>

              <Col xs={24} sm={24} md={12}>              
                <div className="add-btn">
                  <button onClick={handleConfirmModal}>Resend Notification</button>
                </div>
              </Col>
            </Row>


            <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
              <TabPane tab="Detail" key="1">
                {tab1Sec()}
              </TabPane>
              <TabPane tab="Applicants" key="2">
                {singleJob?.applicants && singleJob?.applicants.length >= 1
                  ? tab2sec()
                  : noData("No Applicants Available")}
              </TabPane>
              {/* <TabPane tab="Feedback" key="3">
                {singleJob?.ratings && singleJob?.ratings.length >= 1
                  ? tab3Sec()
                  : noData("No Feedback Available")}
              </TabPane> */}

              <TabPane tab="Notifications" key="4">
                  {tab4sec()}
              </TabPane>

            </Tabs>
          </Card>
        </div>

        {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Resent Notification?"}
          description={"Are you sure you want to send the notification to all the crew members again?"}
          deleteHandler={handleResendNotification}
          selectedData={selectedData}
        />
      )}

      </div>
    );
  }
};

export default Job;
