import React from "react";
import "./styles.scss";

const Logobox = ({ size }) => {
  return (
    <div className={`logobox ${size === "lg" ? "large" : ""}`}>
      <h2>NVOII</h2>
    </div>
  );
};

export default Logobox;
