import React, { createContext, useState } from "react";
import axios from "axios";
import { errorToast } from "../utils";
import { BEARER_TOKEN_HEADERS } from "../utils";
import { toast } from "react-toastify";

export const AppUpdateContext = createContext();

export const AppUpdateContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [appUpdateList, setAppUpdateList] = useState([]);
  const [singleAppUpdateLoader, setSingleAppUpdateLoader] = useState(true);

  const getAppUpdateList = async (page, paginationStatus, query = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/app_updates?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        console.log(res?.data);
        setAppUpdateList(res.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err?.response?.data?.message);
      errorToast(err);
    }
  };

  const createAppUpdate = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/app_updates`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAppUpdateList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const updateAppUpdate = async (id, data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/app_updates/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAppUpdateList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const deleteAppUpdate = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/app_updates/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAppUpdateList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  return (
    <AppUpdateContext.Provider
      value={{
        getAppUpdateList,
        createAppUpdate,
        deleteAppUpdate,
        updateAppUpdate,
        appUpdateList,
        loading,
      }}
    >
      {children}
    </AppUpdateContext.Provider>
  );
};
