import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { NotificationsContext } from "../../context/notificationContext";
import {
  CreateNotificationModal,
  ConfirmationModal,
} from "../../components/Modals/index";
import { getFormattedData } from "../../utils/index";
import DataLoader from "../../components/dataLoader";
import { UsersContext } from "../../context/UsersContext";
import { CrewTypeContext } from "../../context/CrewTypeContext";

const { Title } = Typography;

function NotificationManagement() {
  const {
    getNotificationsList,
    notificationsList,
    deleteNotification,
    createNotification,
    loading,
  } = useContext(NotificationsContext);
  const { getAllActiveUsers, allActiveUsersList } = useContext(UsersContext);
  const { getCrewTypesList, crewTypeLists } = useContext(CrewTypeContext);
  const [showCreateNotificationTypeModal, setShowCreateNotificationTypeModal] =
    useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [page, setPage] = useState(1);
  const { meta, data } = notificationsList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };
  useEffect(() => {
    let query = '&notification_type_id=4';
    getNotificationsList(page, true, query);
  }, [page]);

  useEffect(() => {
    getAllActiveUsers();
    getCrewTypesList();
  }, [page]);
  
  useEffect(()=>{
    console.log("Users List",allActiveUsersList);
  },[allActiveUsersList])
  
  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (data, value) => {
        return <span>{getFormattedData(data)}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "notification_type",
      key: "notification_type",
      render: (data, value) => {
        return <span>{getFormattedData(data?.name)}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (data, record) => {
        return (
          <div className="actions">
            <span>
              {!data?.is_default && (
                <Tooltip title="Delete Notification">
                  <DeleteOutlined onClick={() => handleConfirmModal(record)} />
                </Tooltip>
              )}
            </span>
          </div>
        );
      },
    },
  ];

  //HANDLERS
  const handleCreateModal = () => {
    setShowCreateNotificationTypeModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateNotificationTypeModal(false);
  };

  const handleCreateNotificationType = (data) => {
    createNotification(data, page, true);
  };

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteNotification(id, page, true);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <button onClick={handleCreateModal}>Create Notification</button>
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>Notifications</Title>
                </div>
              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
                expandable={{
                  expandedRowRender: (record) => (
                    <>
                      <h6 style={{ color: "#2992d4" }}>Message</h6>
                      <p
                        style={{ fontWeight: 400, fontSize: 13, color: "#000" }}
                      >
                        {record.message}
                      </p>
                    </>
                  ),
                  rowExpandable: (record) => record.message !== "",
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {showCreateNotificationTypeModal && (
        <CreateNotificationModal
          handleClose={handleCloseCreateModal}
          addType={handleCreateNotificationType}
          usersList={allActiveUsersList}
          crewTypeList={crewTypeLists}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Notification?"}
          description={"Are you sure you want to delete this notification?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}
      {loading && <DataLoader />}
    </>
  );
}

export default NotificationManagement;
