import { Form, Modal, Input, Select, Checkbox, Col, Row, Steps } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { GenericContext } from "../../../context/genericContext";
import { EnvironmentOutlined, GlobalOutlined, UsergroupAddOutlined, DingdingOutlined, DollarOutlined  } from "@ant-design/icons";
import { AirportsContext } from "../../../context/AirportContext";

const CreateJobModal = ({ handleClose, addJob }) => {
  const { getCountries, countries } = useContext(GenericContext);
  const { getAllAirports, airportsListAll } = useContext(AirportsContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  useEffect(() => {
    getCountries();
    getAllAirports();
  }, []);

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    console.log("first", data);
    addJob(data);
    handleClose();
  };

  const handleNext = () => {
    setCurrentStep(currentStep+1);
  }
  const handlePrev = () => {
    setCurrentStep(currentStep-1);
  }


  return (
    <Modal
      visible={true}
      width={800}
      title={<span>Create Job</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">

            <Steps 
              current={currentStep}
              style={{padding:"32px 16px"}}
            >
              <Steps.Step title="Trip" icon={<EnvironmentOutlined />} />
              <Steps.Step title="Crew" icon={<UsergroupAddOutlined />} />
              <Steps.Step title="AirCraft" icon={<DingdingOutlined />} />
              <Steps.Step title="Base" icon={<GlobalOutlined />} />
              <Steps.Step title="Budget" icon={<DollarOutlined />} />
            </Steps>

            {/* Step 1 Fields */}
            <Row gutter={"12"}>
              <Col xs={24} sm={24}>
                <label className="title">
                  Departure Airport<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"departure_id"}
                  rules={[
                    { required: true, message: "Please select Airport!" },
                  ]}
                >
                  <Select
                    placeholder="Please search or select Airport!"
                    name="departure_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {airportsListAll?.map((airport, index) => (
                      <Option value={airport?.id} key={index}>
                        {airport?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Flags <span style={{ color: "red" }}></span>
                </label>
                <Form.Item
                  name={"flags"}
                >
                  <Select
                    placeholder="Please search or select Airport!"
                    name="flags"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {airportsListAll?.map((airport, index) => (
                      <Option value={airport?.id} key={index}>
                        {airport?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>



              <Col xs={24} sm={24}>
                <label className="title">
                  Destination Airport<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"destination_id"}
                  rules={[
                    { required: true, message: "Please select Airport!" },
                  ]}
                >
                  <Select
                    placeholder="Please search or select Airport!"
                    name="destination_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {airportsListAll?.map((airport, index) => (
                      <Option value={airport?.id} key={index}>
                        {airport?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>


            </Row>


          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            {currentStep==4?<span><button className="submit" type="button" onClick={handlePrev}>Prev</button><button className="submit" type="submit">Submit</button></span>:
             currentStep==0?<button className="submit" type="button" onClick={handleNext}>Next</button>:<span><button className="submit" type="button" onClick={handlePrev}>Prev</button> <button className="submit" type="button" onClick={handleNext}>Next</button></span>}
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateJobModal;
