import React, { useState, createContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";

export const FeedbackContext = createContext();

export const FeedbackContextProvider = ({ children }) => {
  const [FeedbacksList, setFeedbacksList] = useState([]);
  const [singleFeedback, setSingleFeedback] = useState({});
  const [loading, setLoading] = useState(false);
  const getFeedbacksList = async (page, paginationStatus, query = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/system_feedbacks?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      setLoading(false);
      setFeedbacksList(res?.data);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getSingleFeedback = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/system_feedbacks/${id}`,
        BEARER_TOKEN_HEADERS
      );
      setSingleFeedback(res?.data);
    } catch (err) {
      setSingleJobLoader(false);
      errorToast(err);
    }
  };

  const deleteFeedback = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/system_feedbacks/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getFeedbacksList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <FeedbackContext.Provider
      value={{
        getFeedbacksList,
        FeedbacksList,
        getSingleFeedback,
        singleFeedback,
        deleteFeedback,
        loading,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};
