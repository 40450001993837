import React, { useContext, useEffect } from "react";
import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import { WingTypeContext } from "../../../context/WingTypeContext";
import { ManufactureContext } from "../../../context/ManufacturerContext";
import "../styles.scss";
const UpdateAircraftModal = ({ handleClose, selectedData, updateData }) => {
  const { getWingTypesList, wingTypeLists } = useContext(WingTypeContext);
  const { getManufacturesList, manufactureLists } =
    useContext(ManufactureContext);
  const [form] = Form.useForm();
  const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    let tempData = {
      name: data.name,
      icao: data.icao ? data.icao : "",
      iata: data.iata ? data.iata : "",
      manufacturer_id: data.manufacturer_id,
      wing_type_id: data.wing_type_id,
      type: data.type ? data.type : "",
      is_active: data.is_active ? data.is_active : false,
    };
    updateData(tempData, selectedData.id);
    handleClose();
  };
  useEffect(() => {
    getWingTypesList(1, false);
    getManufacturesList(1, false);
    form.setFieldsValue({
      name: selectedData.name,
      icao: selectedData.icao,
      iata: selectedData.iata,
      manufacturer_id: selectedData.manufacturer_id,
      wing_type_id: selectedData.wing_type_id,
      type: selectedData.type,
      is_active: selectedData.is_active,
    });
  }, []);
  console.log("selected", selectedData);
  return (
    <Modal
      visible={true}
      title={<span>Update Aircraft</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <label className="title">
              Name<span style={{ color: "red" }}>*</span>
            </label>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input name!",
                },
              ]}
            >
              <Input name="name" placeholder="Name..." />
            </Form.Item>
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <label className="title">ICAO</label>
                <Form.Item name="icao">
                  <Input name="icao" placeholder="Icao..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">IATA</label>
                <Form.Item name="iata">
                  <Input name="iata" placeholder="Iata..." />
                </Form.Item>
              </Col>
            </Row>
            <label className="title">Type</label>
            <Form.Item name="type">
              <Input name="type" placeholder="Type..." />
            </Form.Item>
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <label className="title">
                  Manufacturer<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"manufacturer_id"}
                  rules={[
                    { required: true, message: "Please select manufacturer!" },
                  ]}
                >
                  <Select
                    placeholder="Please select a manufacturer!"
                    name="country_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {manufactureLists?.map((data, index) => (
                      <Option value={data?.id} key={index}>
                        {data?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Wing Type<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"wing_type_id"}
                  rules={[
                    { required: true, message: "Please select wing type!" },
                  ]}
                >
                  <Select
                    placeholder="Please select a wing type!"
                    name="wing_type_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {wingTypeLists?.map((data, index) => (
                      <Option value={data?.id} key={index}>
                        {data?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="is_active" valuePropName="checked">
              <Checkbox checked={false} value={false}>
                Is Active?
              </Checkbox>
            </Form.Item>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdateAircraftModal;
