import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { UserOutlined, FileFilled } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Loader from "../../Loader/index";
import {
  Row,
  Col,
  Card,
  Typography,
  Collapse,
  Tabs,
  Avatar,
  Space,
  Modal,
  Image,
  Tooltip,
} from "antd";
import { CrewContext } from "../../../context/CrewContext";
import AdminDataTable from "../../Layouts/sharedAdminLayout/partials/data-table";
import { getFormattedDateTime ,getFormattedDate } from "../../../utils/index";

const Crew = () => {
  const { TabPane } = Tabs;
  const { crewId } = useParams();
  const { Title, Paragraph } = Typography;
  const { Panel } = Collapse;
  const { getSingleCrew, data, singleCrewLoader } = useContext(CrewContext);

  useEffect(() => {
    getSingleCrew(crewId);
  }, []);

  const [modalVisible, setModalVisible] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState("");

  const handlePhotoClick = (photoUrl) => {
    setModalVisible(true);
    setLargeImageUrl(photoUrl);
  };

  if (singleCrewLoader) {
    return <Loader />;
  } else {
    const tab1Sec = () => (
      <>
        <Row gutter={[6, 0]}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <div className="profile-photo centered-content">
              <Space direction="vertical" align="center" size={100}>
                <Space wrap size={100}>
                  {data.user?.photo_thumb !== null &&
                  data.user?.photo_thumb !== "" ? (
                    <Avatar
                      size={150}
                      src={data.user?.photo_thumb}
                      onClick={() => handlePhotoClick(data.user?.photo)}
                    />
                  ) : (
                    <Avatar size={150} icon={<UserOutlined />} />
                  )}
                </Space>
              </Space>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={18} xl={18}>
            <div className="profile-details">
              <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">User Name</h5>
                  <p className="detail-description">
                    {data?.user?.firstname} {data?.user?.lastname}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Rating</h5>
                  <p className="detail-description">{data?.rating}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Crew type</h5>
                  <p className="detail-description">{data?.crew_type?.name}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Certification Number</h5>
                  <p className="detail-description">
                    {data?.certification?.certificate_number ?? "-"}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Current Location</h5>
                  <p className="detail-description">{data?.current_location}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Country</h5>
                  <p className="detail-description">{data?.country?.name}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Phone Number</h5>
                  <p className="detail-description">
                    {data?.user?.phone_code} {data?.user?.phone}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Email Address</h5>
                  <p className="detail-description">{data?.user?.email}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">PIC Hour</h5>
                  <p className="detail-description">
                    {data?.flight_hour?.pic_hours ?? "-"}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">SIC Hour</h5>
                  <p className="detail-description">
                    {data?.flight_hour?.sic_hours ?? "-"}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Total Hour</h5>
                  <p className="detail-description">
                    {data?.flight_hour?.total_hours ?? "-"}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Certification Number</h5>
                  <p className="detail-description">
                    {data?.certification?.certificate_number ?? "-"}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Certification Agency</h5>
                  <p className="detail-description">
                    {data?.certification?.certification_agency.name ?? "-"}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Certification Rating</h5>
                  <p className="detail-description">
                    {data?.certification?.rating?.name ?? "-"}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">How Did you hear about us ?</h5>
                  <p className="detail-description">
                    {data?.user?.referral_source?.referral_source?.name ?? "-"}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Joined at</h5>
                  <p className="detail-description">
                    {getFormattedDateTime(data?.created_at)}
                  </p>
                </Col>

              </Row>
            </div>
          </Col>
        </Row>
        {modalVisible && (
          <div className="layout-content">
            <div className="mb-24">
              <Card bordered={false} className="criclebox">
                {/* Rest of the code */}
              </Card>
            </div>

            <Modal
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
              <img
                src={largeImageUrl}
                alt="Large Avatar"
                style={{ width: "100%" }}
              />
            </Modal>
          </div>
        )}
      </>
    );

    const documents = () => {
      return (
        <>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="profile-details">
                <h3>Government IDs</h3>
              </div>
              <br />
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {data?.government_ids.map((document, index) => {
                return <>
                  <Image
                    key={index}
                    src={document.image_thumb}
                    alt={`Document ${index}`}
                    className="document-image"
                    preview={{
                      src: document.image,
                    }}
                  />
                </>
              })}
            </Col>
          </Row>
          <br />
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="profile-details">
                <h3>Certificates</h3>
              </div>
              <br />
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {data?.certificates.map((document, index) => {
                return <>
                  <Image
                    key={index}
                    src={document.image_thumb}
                    alt={`Document ${index}`}
                    className="document-image"
                    preview={{
                      src: document.image,
                    }}
                  />
                </>
              })}
            </Col>
          </Row>
          <br />
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="profile-details">
                <h3>Log Books</h3>
              </div>
              <br />
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {data?.logbooks?.map((document, index) => {
                return <>
                  <Image
                    key={index}
                    src={document.image_thumb}
                    alt={`Document ${index}`}
                    className="document-image"
                    preview={{
                      src: document.image,
                    }}
                  />
                </>
              })}
            </Col>
          </Row>
          <br />
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="profile-details">
                <h3>Vaccination Cards</h3>
              </div>
              <br />
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {data?.vaccination_cards.map((document, index) => {
                return <>
                  <Avatar 
                    size={150} 
                    icon={<FileFilled />} 
                    className="document-image"
                    onClick={() => {
                      window.open(document.doc, '_blank')
                    }}
                  />
                </>
              })}
            </Col>
          </Row>
          <br />
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="profile-details">
                <h3>Resume</h3>
              </div>
              <br />
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Avatar 
                size={150} 
                icon={<FileFilled />} 
                className="document-image"
                onClick={() => window.open(data.resume.doc, '_blank')}
              />
            </Col>
          </Row>
        </>
      );
    };

    const tab2sec = () => {
      const columns = [
        {
          title: "No",
          dataIndex: "No",
          key: "No",
          render: (data, value, i) => <b>{i + 1}</b>,
        },
        {
          title: "Visas",
          dataIndex: "name",
          key: "name",
          render: (data, value) => <span>{data}</span>,
        },
      ];
      return <AdminDataTable columns={columns} data={data?.visas} />;
    };
    
    const spokenLanguages = () => {
      const columns = [
        {
          title: "No",
          dataIndex: "No",
          key: "No",
          render: (data, value, i) => <b>{i + 1}</b>,
        },
        {
          title: "Code",
          dataIndex: "code",
          key: "code",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Native Name",
          dataIndex: "native",
          key: "native",
          render: (data, value) => <span>{data}</span>,
        },
      ];
      return <AdminDataTable columns={columns} data={data?.spoken_languages} />;
    };

    const vaccinations = () => {
      const columns = [
        {
          title: "No",
          dataIndex: "No",
          key: "No",
          render: (data, value, i) => <b>{i + 1}</b>,
        },
        {
          title: "Vaccinations",
          dataIndex: "name",
          key: "name",
          render: (data, value) => <span>{data}</span>,
        },
      ];
      return <AdminDataTable columns={columns} data={data?.vaccinations} />;
    };

    const tab3sec = () => {
      const columns = [
        {
          title: "Company",
          dataIndex: "company",
          key: "company",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Start Year",
          dataIndex: "start_year",
          key: "start_year",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "End Year",
          dataIndex: "end_year",
          key: "end_year",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Currently Employed",
          dataIndex: "presently_employed",
          key: "presently_employed",
          render: (data, value) => <span>{data ? "Yes" : "No"}</span>,
        },
      ];
      return <AdminDataTable columns={columns} data={data?.experiences} />;
    };
    const tab4sec = () => {
      const columns = [
        {
          title: "Card Number",
          dataIndex: "last4",
          key: "last4",
          render: (data, value) => <span>******{data}</span>,
        },
        {
          title: "Brand",
          dataIndex: "brand",
          key: "brand",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Country",
          dataIndex: "country",
          key: "country",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Expiration Month",
          dataIndex: "exp_month",
          key: "exp_month",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Expiration Year",
          dataIndex: "exp_year",
          key: "exp_year",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Default",
          dataIndex: "is_default",
          key: "is_default",
          render: (data, value) => <span>{data ? "Yes" : "No"}</span>,
        },
      ];
      return <AdminDataTable columns={columns} data={data?.payment_methods} />;
    };
    const tab5sec = () => {
      const columns = [
        {
          title: "Quantity",
          dataIndex: "quantity",
          key: "quantity",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Unit",
          dataIndex: "unit",
          key: "unit",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Total Price",
          dataIndex: "total",
          key: "total",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Popular",
          dataIndex: "is_popular",
          key: "is_popular",
          render: (data, value) => <span>{data ? "Yes" : "No"}</span>,
        },
      ];
      return (
        <AdminDataTable columns={columns} data={data?.subscription_plans} />
      );
    };

    const tab6sec = () => {
      const columns = [
        {
          title: "Aircraft",
          dataIndex: "aircraft",
          key: "aircraft",
          render: (data, value) => <span>{data?.name}</span>,
        },
        {
          title: "Manufacturer",
          dataIndex: "manufacturer",
          key: "manufacturer",
          render: (data, value) => <span>{data?.name}</span>,
        },
        {
          title: "Hours",
          dataIndex: "hours",
          key: "hours",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Daily Rate",
          dataIndex: "daily_rate",
          key: "daily_rate",
          render: (data, value) => <span>{data}</span>,
        },
      ];
      return <AdminDataTable columns={columns} data={data?.type_ratings} />;
    };
    
    const aircraftRates = () => {
      const columns = [
        {
          title: "Aircraft",
          dataIndex: "aircraft",
          key: "aircraft",
          render: (data, value) => <span>{data?.name}</span>,
        },
        {
          title: "Manufacturer",
          dataIndex: "manufacturer",
          key: "manufacturer",
          render: (data, value) => <span>{data?.name}</span>,
        },
        {
          title: "Flight Experience",
          dataIndex: "flight_experience",
          key: "flight_experience",
          render: (data, value) => <span>{data?.name}</span>,
        },
        {
          title: "Daily Rate",
          dataIndex: "daily_rate",
          key: "daily_rate",
          render: (data, value) => <span>{data}</span>,
        },
      ];
      return <AdminDataTable columns={columns} data={data?.aircraft_rates} />;
    };

    const medicalCertificates = () => {
      const columns = [
        {
          title: "No",
          dataIndex: "No",
          key: "No",
          render: (data, value, i) => <b>{i + 1}</b>,
        },
        {
          title: "Certification Agency",
          dataIndex: "certification_agency",
          key: "certification_agency",
          render: (data, value) => <span>{data?.name}</span>,
        },
        {
          title: "Medical Certificate Class",
          dataIndex: "medical_certificate_class",
          key: "medical_certificate_class",
          render: (data, value) => <span>{data?.name}</span>,
        },
        {
          title: "Expiry Date",
          dataIndex: "expiry_date",
          key: "expiry_date",
          render: (data, value) => <span>{getFormattedDate(data)}</span>,
        },
      ];
      return (
        <AdminDataTable columns={columns} data={data?.medical_certificates} />
      );
    };

    const jobs = () => {
      const columns = [
        {
          title: "Trip No",
          dataIndex: "trip_id",
          key: "trip_id",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          render: (data, value) => <span>{data}</span>,
        },
        {
          title: "Company Name",
          dataIndex: "client",
          key: "client",
          render: (data, value) => <span>{data?.company_name}</span>,
        },
        {
          title: "Status",
          dataIndex: "job_status",
          key: "job_status",
          render: (data, value) => <span>{data?.name}</span>,
        },
        {
          title: "Action",
          key: "action",
          render: (text, record) => {
            const { slug, id } = record;
            return (
              <div className="actions">
                <Link to={`/admin/job/${slug}/${id}`} className="preview">
                  <Tooltip title="View job">
                    <FontAwesomeIcon icon={faEye} />
                  </Tooltip>
                </Link>
              </div>
            );
          },
        },
      ];
      return <AdminDataTable columns={columns} data={data?.jobs} />;
    };

    const jobRatings = () => {
      const columns = [
        {
          title: "No",
          dataIndex: "No",
          key: "No",
          render: (data, value, i) => <b>{i + 1}</b>,
        },
        {
          title: "Company Name",
          dataIndex: "client",
          key: "client",
          render: (data, value) => <span>{data?.company_name}</span>,
        },
        {
          title: "Rating",
          dataIndex: "rating",
          key: "rating",
          render: (data, value) => <span>{data ?? "-"}</span>,
        },
        {
          title: "Comment",
          dataIndex: "comment",
          key: "comment",
          render: (data, value) => <span>{data ?? "-"}</span>,
        },
      ];
      return <AdminDataTable columns={columns} data={data?.job_ratings} />;
    };
    const noData = (message) => <p className="no-data">{message}</p>;
    return (
      <div className="layout-content">
        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <Title level={5}>{data?.headline}</Title>
            <Paragraph className="lastweek" style={{ marginBottom: 5 }}>
              {data?.brief}
            </Paragraph>
            <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
              <TabPane tab="Detail" key="1">
                {tab1Sec()}
              </TabPane>
              <TabPane tab="Visa" key="2">
                {data?.visas && data?.visas.length >= 1
                  ? tab2sec()
                  : noData("Visa Not Available")}
              </TabPane>
              <TabPane tab="Spoken Languages" key="spokenLanguages">
                {data?.spoken_languages && data?.spoken_languages.length >= 1
                  ? spokenLanguages()
                  : noData("Spoken Languages Not Defined")}
              </TabPane>
              <TabPane tab="Vaccinations" key="vaccinations">
                {data?.vaccinations && data?.vaccinations.length >= 1
                  ? vaccinations()
                  : noData("Vaccinations Not Available")}
              </TabPane>
              <TabPane tab="Experience" key="3">
                {data?.experiences && data?.experiences.length >= 1
                  ? tab3sec()
                  : noData("Experience Not Available")}
              </TabPane>
              <TabPane tab="Payment Methods" key="4">
                {data?.payment_methods && data?.payment_methods.length >= 1
                  ? tab4sec()
                  : noData("Payment Methods Not Available")}
              </TabPane>
              <TabPane tab="Subscrption Plans" key="5">
                {data?.subscription_plans &&
                data?.subscription_plans.length >= 1
                  ? tab5sec()
                  : noData("Subscription Plans Not Available")}
              </TabPane>
              <TabPane tab="Medical Certificates" key="medicalCertificates">
                {data?.medical_certificates &&
                data?.medical_certificates.length >= 1
                  ? medicalCertificates()
                  : noData("Medical Certificates Not Available")}
              </TabPane>
              <TabPane tab="Jobs" key="jobs">
                {data?.jobs && data?.jobs.length >= 1
                  ? jobs()
                  : noData("Jobs Not Available")}
              </TabPane>
              { data?.crew_type?.name === "Flight Attendant" ?
                <TabPane tab="Aircraft Rates" key="aircraftRates">
                {data?.aircraft_rates && data?.aircraft_rates.length >= 1
                  ? aircraftRates()
                  : noData("Aircraft Rates Not Available")}
                </TabPane>
                :
                <TabPane tab="Type Ratings" key="6">
                  {data?.type_ratings && data?.type_ratings.length >= 1
                    ? tab6sec()
                    : noData("Rating Not Available")}
                </TabPane>
              }
              <TabPane tab="Job Ratings" key="jobRating">
                {data?.job_ratings && data?.job_ratings.length >= 1
                  ? jobRatings()
                  : noData("Job Rating Not Available")}
              </TabPane>
              <TabPane tab="Documents" key="documents">
                {(data?.certificates && data?.certificates.length >= 1) ||
                (data?.resume && data?.resume.length >= 1) ||
                (data?.logbooks && data?.logbooks.length >= 1) ||
                (data?.vaccination_cards &&
                  data?.vaccination_cards.length >= 1) ||
                (data?.government_ids && data?.government_ids.length >= 1)
                  ? documents()
                  : noData("Documents Not Available")}
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    );
  }
};

export default Crew;
