import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Typography,
  Col,
  Row,
  Radio,
  Button,
  Tooltip,
  Form,
  Select,
  DatePicker,
  Input,
  Dropdown,
  Space,
  Menu,
} from "antd";
import { FilterFilled } from "@ant-design/icons";
import { faEye, faTrash, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ConfirmationModal } from "../../components/Modals/index";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { JobsContext } from "../../context/JobsContext";
import { JobTypesContext } from "../../context/JobTypeContext";
import { GenericContext } from "../../context/genericContext";
import { CrewTypeContext } from "../../context/CrewTypeContext";
import { ManufactureContext } from "../../context/ManufacturerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import DataLoader from "../../components/dataLoader";
import { getFormattedDate } from "../../utils";
import FilterDrawer from "../../components/filterDrawer";
import { CreateJobModal } from "../../components/Modals";
import { getFormattedDateTime } from "../../utils";



function JobsManagement() {
  const { RangePicker } = DatePicker;
  const { getJobsList, jobsList, deleteJob, approveJob, rejectJob, loading, createJob } = useContext(JobsContext);
  const { getJobTypessList, JobTypesLists } = useContext(JobTypesContext);
  const { getCountries, countries } = useContext(GenericContext);
  const { getCrewTypesList, crewLists } = useContext(CrewTypeContext);
  const { getManufacturesList, manufactureLists } =
    useContext(ManufactureContext);
  const [selectedData, setselectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationApproveModal, setShowConfirmationApproveModal] = useState(false);
  const [showConfirmationRejectModal, setShowConfirmationRejectModal] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [countryData, setcountryData] = useState([]);
  const [crewTypeData, setcrewTypeData] = useState([]);
  const [manufacturerData, setmanufacturerData] = useState([]);
  const [jobTypesData, setJobTypesData] = useState([]);
  const [showCreateJobModal, setShowCreateJobModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [page, setPage] = useState(1);

  const { meta, data } = jobsList;
  const { Title, Paragraph } = Typography;

  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };
  const onChange = (e) => {
    setFilterStatus(e.target.value);
  };
  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    if (Array.isArray(credentials.date)) {
      credentials["from"] = getFormattedDate(credentials.date[0]);
      credentials["to"] = getFormattedDate(credentials.date[1]);
    }
    setFilterOpen(false);
    setFilteredData(credentials);
  };
  const handleResetForm = () => {
    form.resetFields();
    setFilteredData(null);
    form.setFieldsValue({
      country_id: undefined,
      crew_type_id: undefined,
      manufacturer_id: undefined,
      date: undefined,
    });
    setFilterOpen(false);
  };

  useEffect(() => {
    setcountryData(Array.isArray(countries) ? countries : countries.data);
  }, [countries]);

  useEffect(() => {
    setcrewTypeData(Array.isArray(crewLists) ? crewLists : crewLists.data);
  }, [crewLists]);

  useEffect(() => {
    setmanufacturerData(
      Array.isArray(manufactureLists) ? manufactureLists : manufactureLists.data
    );
  }, [manufactureLists]);

  useEffect(() => {
    setJobTypesData(
      Array.isArray(JobTypesLists) ? JobTypesLists : JobTypesLists.data
    );
  }, [JobTypesLists]);


  useEffect(() => {
    const temp = jobsList?.data?.filter((job) =>
      filterStatus === "active"
        ? job?.is_active
        : filterStatus === "inactive"
        ? !job?.is_active
        : job
    );
    setFilteredJobs(temp);
  }, [filterStatus, data]);

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  useEffect(() => {
    let filterQuery = "&job_type_id=1,2";
    if (filteredData) {
      Object.keys(filteredData).forEach(function (key) {
        if (filteredData[key] && key !== "date") {
          filterQuery += `&${key}=${filteredData[key]}`;
        }
      });
    }
    getJobsList(page, true, filterQuery);
  }, [page, filteredData]);

  useEffect(() => {
    getCountries();
    getCrewTypesList(1, false);
    getManufacturesList(1, false);
    getJobTypessList(1, false);
  }, []);

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };


  const menu = (
    <Menu>
      <Menu.Item key="1">  
        <Link to={`/admin/createJob`} className="preview">Contract</Link>
      </Menu.Item>
      {/* <Menu.Item key="2">  
        <Link to={`/admin/createJobFullTime`} className="preview">Full-time</Link>
      </Menu.Item> */}
    </Menu>
  );

  const columns = [
    {
      title: "Trip ID",
      dataIndex: "trip_id",
      key: "trip_id",
      render: (data, value) => <span>{data}</span>,
    },
    {
      title: "Trip title",
      dataIndex: "title",
      key: "title",
      render: (data, value) => <span>{data}</span>,
    },
    {
      title: "Trip type",
      dataIndex: "job_type",
      key: "job_type",
      render: (data, value) => <span>{data?.name}</span>,
    },
    {
      title: "Trip status",
      dataIndex: "job_status",
      key: "job_status",
      render: (data, value) => <span>{data?.name}</span>,
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, value) => <span>{getFormattedDateTime(data)}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const { slug, id } = record;
        if(record.job_status.id == 1){
          return (
            <div className="actions">
              
              <span className="approve">
                <Tooltip title="Approve Job">
                  <FontAwesomeIcon
                    icon={faCheck}
                    onClick={() => handleConfirmApproveModal(record)}
                  />
                </Tooltip>
              </span>
              
              <span className="reject">
                <Tooltip title="Reject Job">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => handleConfirmRejectModal(record)}
                  />
                </Tooltip>
              </span>
  
  
              <Link to={`trip/${slug}/${id}`} className="preview">
                <Tooltip title="View Trip">
                  <FontAwesomeIcon icon={faEye} />
                </Tooltip>
              </Link>
              <span>
                <Tooltip title="Delete Job">
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleConfirmModal(record)}
                  />
                </Tooltip>
              </span>
            </div>
          );
  
        }else{
        
          return (
            <div className="actions">  
              <Link to={`trip/${slug}/${id}`} className="preview">
                <Tooltip title="View Trip">
                  <FontAwesomeIcon icon={faEye} />
                </Tooltip>
              </Link>
              <span>
                <Tooltip title="Delete Job">
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleConfirmModal(record)}
                  />
                </Tooltip>
              </span>
            </div>
          );          

        }
      },
    },
  ];

  const drawerSec = () => (
    <>
      {isFilterOpen && (
        <Form
          form={form}
          className="form"
          initialValues={filteredData}
          onFinish={handleFormFinish}
        >
          <label className="form-lbl">Trip ID</label>
          <Form.Item name={"trip_id"}>
            <Input type="text" placeholder="Filter by Trip ID"  className="form-input" />
          </Form.Item>
          <label className="form-lbl">Country:</label>
          <Form.Item name={"country_id"}>
            <Select
              placeholder="Filter by country"
              name="country_id"
              className="form-select"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {countryData.map((data, index) => (
                <Option value={data.id} key={index}>
                  {data.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <label className="form-lbl">Crew type:</label>
          <Form.Item name={"crew_type_id"}>
            <Select
              placeholder="Filter by crew type"
              name="crew_type_id"
              className="form-select"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {crewTypeData.map((data, index) => (
                <Option value={data.id} key={index}>
                  {data.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <label className="form-lbl">Manufacturer:</label>
          <Form.Item name={"manufacturer_id"}>
            <Select
              placeholder="Filter by manufacturer"
              name="manufacturer_id"
              className="form-select"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {manufacturerData.map((data, index) => (
                <Option value={data.id} key={index}>
                  {data.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* <label className="form-lbl">Job Type:</label>
          <Form.Item name={"job_type_id"}>
            <Select
              placeholder="Filter by Job Type"
              name="job_type_id"
              className="form-select"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {JobTypesLists.map((data, index) => (
                <Option value={data.id} key={index}>
                  {data.name}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
          <label className="form-lbl">Date:</label>
          <Form.Item name={"date"}>
            <RangePicker className="form-input" />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              className="form-btn"
              style={{ minWidth: "100%" }}
            >
              Apply Filters
            </Button>
            <span className="form-rm-btn" onClick={handleResetForm}>
              Reset Filters
            </span>
          </Form.Item>
        </Form>
      )}
    </>
  );

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteJob(id, page, true);
  };

  const handleConfirmApproveModal = (data) => {
    setselectedData(data);
    setShowConfirmationApproveModal(true);
  };
  const handleConfirmationApproveModalClose = () => {
    setShowConfirmationApproveModal(false);
  };

  const handleConfirmRejectModal = (data) => {
    setselectedData(data);
    setShowConfirmationRejectModal(true);
  };
  const handleConfirmationRejectModalClose = () => {
    setShowConfirmationRejectModal(false);
  };

  const handleApproveJob = (id) => {
    approveJob(id, page, true);
  };
  const handleRejectJob = (id) => {
    rejectJob(id, page, true);
  };

  const handleCreateModal = () => {
    setShowCreateJobModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateJobModal(false);
  };
  const handleCreateJob = (data) => {
    createJob(data, page, true);
  };
  
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
           <div className="add-btn">
              <Dropdown overlay={menu}>
               <Button style={{padding:"0px 22px"}}>Create Trip</Button>
              </Dropdown>
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>Trips</Title>
                  <Paragraph className="lastweek">
                    {/* done this month<span className="blue">40%</span> */}
                  </Paragraph>
                </div>

                <Button className="filter-btn" onClick={filterHandler}>
                  <FilterFilled />
                  Filter
                </Button>
                {/* <div className="ant-filtertabs mb-24">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <Radio.Group onChange={onChange} defaultValue="all">
                      <Radio.Button value="all">ALL</Radio.Button>
                      <Radio.Button value="active">ACTIVE</Radio.Button>
                      <Radio.Button value="inactive">IN-ACTIVE</Radio.Button>
                    </Radio.Group>
                  </div>
                </div> */}
              </div>
              <AdminDataTable
                data={filteredJobs}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>

      {showCreateJobModal && (
        <CreateJobModal
          handleClose={handleCloseCreateModal}
          addJob={handleCreateJob}
        />
      )}


      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Job?"}
          description={"Are you sure you want to delete this job?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}
      {showConfirmationApproveModal && (
        <ConfirmationModal
          handleClose={handleConfirmationApproveModalClose}
          title={"Approve Job?"}
          description={"Are you sure you want to Approve this job?"}
          deleteHandler={handleApproveJob}
          selectedData={selectedData}
        />
      )}
      {showConfirmationRejectModal && (
        <ConfirmationModal
          handleClose={handleConfirmationRejectModalClose}
          title={"Reject Job?"}
          description={"Are you sure you want to Reject this job?"}
          deleteHandler={handleRejectJob}
          selectedData={selectedData}
        />
      )}


      {loading && <DataLoader />}
    </>
  );
}

export default JobsManagement;
