import React, { createContext, useState } from "react";
import axios from "axios";
import { errorToast } from "../utils";
import { BEARER_TOKEN_HEADERS } from "../utils";
import { toast } from "react-toastify";

export const WingTypeContext = createContext();

export const WingTypeContextProvider = ({ children }) => {
  const [laoding, setlaoding] = useState(false);
  const [wingTypeLists, setWingTypeLists] = useState([]);
  const getWingTypesList = async (page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/wing_types?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        setWingTypeLists(res.data);
      }
      setlaoding(false);
    } catch (err) {
      setlaoding(false);
      console.log(err?.response?.data?.message);
      errorToast(err);
    }
  };

  const createWingType = async (name, page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/wing_types`,
        { name },
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getWingTypesList(page, paginationStatus);
      setlaoding(false);
    } catch (err) {
      errorToast(err);
      setlaoding(false);
    }
  };

  const updateWingType = async (name, id, page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/wing_types/${id}`,
        { name },
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getWingTypesList(page, paginationStatus);
      setlaoding(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setlaoding(false);
    }
  };

  const deleteWingType = async (id, page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/wing_types/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getWingTypesList(page, paginationStatus);
      setlaoding(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setlaoding(false);
    }
  };

  return (
    <WingTypeContext.Provider
      value={{
        getWingTypesList,
        createWingType,
        updateWingType,
        deleteWingType,
        wingTypeLists,
        laoding,
      }}
    >
      {children}
    </WingTypeContext.Provider>
  );
};
