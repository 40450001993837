import React, { useState, createContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { Redirect } from "react-router-dom";

export const JobsContext = createContext();

export const JobsContextProvider = ({ children }) => {
  const [jobsList, setJobsList] = useState([]);
  const [singleJob, setSingleJob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [jobsRedirect, setJobsRedirect] = useState(false);
  const [singleJobLoader, setSingleJobLoader] = useState(false);
  console.log(BEARER_TOKEN_HEADERS);
  const getJobsList = async (page, paginationStatus, query = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/jobs?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      setLoading(false);
      setJobsList(res?.data);
      console.log("jobs list result", res?.data);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getSingleJob = async (id) => {
    setSingleJobLoader(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/jobs/${id}`,
        BEARER_TOKEN_HEADERS
      );
      console.log("crew list result", res?.data);
      setSingleJob(res?.data);
      setSingleJobLoader(false);
    } catch (err) {
      setSingleJobLoader(false);
      errorToast(err);
    }
  };

  const createJob = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV_V2}/jobs`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("Job Created",res?.data);
      toast.success(res?.data?.message);
      setLoading(false);
      setJobsRedirect(true);
      // getJobsList(page, paginationStatus);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const deleteJob = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/jobs/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getJobsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const approveJob = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      let data = {};
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/jobs/${id}/approve_admin`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("Approve Response", res);
      setLoading(false);
      getJobsList(page, paginationStatus);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const rejectJob = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      let data = {};
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/jobs/${id}/reject_admin`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("Reject Response", res?.data);
      setLoading(false);
      getJobsList(page, paginationStatus);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };



  return (
    <JobsContext.Provider
      value={{
        getJobsList,
        getSingleJob,
        approveJob,
        rejectJob,
        jobsList,
        singleJob,
        singleJobLoader,
        deleteJob,
        loading,
        createJob,
        jobsRedirect
      }}
    >
      {children}
    </JobsContext.Provider>
  );
};
