// import { useState } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faUsers,
  faSignOutAlt,
  faPlane,
  faUser,
  faBriefcase,
  faIndustry,
  faPlaneDeparture,
  faMoneyBill,
  faUserAstronaut,
  faWarehouse,
  faTag,
  faBuilding,
  faComments,
  faCommentDots,
  faBan,
  faBell,
  faImages,
  faRecycle,
} from "@fortawesome/free-solid-svg-icons";
import Logobox from "../../../logoBox";

const { SubMenu } = Menu;
function Sidenav({ page, logout }) {
  const Logout = () => {
    logout();
  };
  return (
    <>
      <div className="brand">
        <NavLink to="/">
          <Logobox />
        </NavLink>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/admin/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faChartLine} />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/admin/roles">
            <span
              className="icon"
              style={{
                background: page === "roles" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faUsers} />
            </span>
            <span className="label">Roles</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/admin/trips">
            <span
              className="icon"
              style={{
                background: page === "jobs" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faBriefcase} />
            </span>
            <span className="label">Trips</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4.1">
          <NavLink to="/admin/jumpseats">
            <span
              className="icon"
              style={{
                background: page === "jumpseats" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faBriefcase} />
            </span>
            <span className="label">Jumpseats</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/admin/crew-types">
            <span
              className="icon"
              style={{
                background: page === "crew-types" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faUserAstronaut} />
            </span>
            <span className="label">Crew Types</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/admin/crews">
            <span
              className="icon"
              style={{
                background: page === "crews" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faUserAstronaut} />
            </span>
            <span className="label">Crews</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/admin/clients">
            <span
              className="icon"
              style={{
                background: page === "clients" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faUser} />
            </span>
            <span className="label">Clients</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/admin/wing-types">
            <span
              className="icon"
              style={{
                background: page === "wing-types" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faWarehouse} />
            </span>
            <span className="label">Wing Types</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="9">
          <NavLink to="/admin/aircrafts">
            <span
              className="icon"
              style={{
                background: page === "aircrafts" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faPlane} />
            </span>
            <span className="label">Aircrafts</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="10">
          <NavLink to="/admin/manufacturers">
            <span
              className="icon"
              style={{
                background: page === "manufacturers" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faIndustry} />
            </span>
            <span className="label">Manufacturers</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="11">
          <NavLink to="/admin/certification-agencies">
            <span
              className="icon"
              style={{
                background: page === "certification-agencies" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faBuilding} />
            </span>
            <span className="label">Certification Agencies</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="12">
          <NavLink to="/admin/subscriptions">
            <span
              className="icon"
              style={{
                background: page === "subscriptions" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faTag} />
            </span>
            <span className="label">Subscription Plans</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="13">
          <NavLink to="/admin/system-feedbacks">
            <span
              className="icon"
              style={{
                background: page === "system-feedbacks" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faComments} />
            </span>
            <span className="label">System Feedbacks</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="14">
          <NavLink to="/admin/system-feedback-options">
            <span
              className="icon"
              style={{
                background: page === "system-feedback-options" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faCommentDots} />
            </span>
            <span className="label">System Feedback Opt</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="15">
          <NavLink to="/admin/cancellation-reasons">
            <span
              className="icon"
              style={{
                background: page === "cancellation-reasons" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faBan} />
            </span>
            <span className="label">Cancellation Reasons</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="16">
          <NavLink to="/admin/notifications">
            <span
              className="icon"
              style={{
                background: page === "notifications" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faBell} />
            </span>
            <span className="label">Notifications</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="17">
          <NavLink to="/admin/notification-types">
            <span
              className="icon"
              style={{
                background: page === "notification-types" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faBell} />
            </span>
            <span className="label">Notification Types</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="18">
          <NavLink to="/admin/airports">
            <span
              className="icon"
              style={{
                background: page === "airports" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faPlaneDeparture} />
            </span>
            <span className="label">Airports</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="19">
          <NavLink to="/admin/average-daily-rates">
            <span
              className="icon"
              style={{
                background: page === "average-daily-rates" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faMoneyBill} />
            </span>
            <span className="label">Average Daily Rates</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="20">
          <NavLink to="/admin/banners">
            <span
              className="icon"
              style={{
                background: page === "banners" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faImages} />
            </span>
            <span className="label">Banners</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="21">
          <NavLink to="/admin/appUpdates">
            <span
              className="icon"
              style={{
                background: page === "appUpdates" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faRecycle} />
            </span>
            <span className="label">App Updates</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="22">
          <span className="unlink" onClick={Logout}>
            <span className="icon">
              <FontAwesomeIcon icon={faSignOutAlt} />
            </span>
            <span className="label">Logout</span>
          </span>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
