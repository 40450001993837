import { Input, Modal, Select } from "antd";
import React, { useState } from "react";
import "../styles.scss";
import ImageUploader from "../../imageUploader";
const UpdateBannerModal = ({ handleClose, selectedData, updateBanner }) => {
  console.log(selectedData);
  const [link, setLink] = useState(selectedData.link);
  const [linkType, setLinkType] = useState(selectedData.link_type?selectedData.link_type:"external");
  const [sortOrder, setSortOrder] = useState(selectedData.sort_order);
  const [type, setType] = useState(selectedData.type);
  const [banner, setBanner] = useState(selectedData.banner);
  const [location, setLocation] = useState(selectedData.location);
  const [error, setError] = useState("");
  const updateImage = (data) => {
    console.log("data", data);
    setBanner(data);
  };
  const handleAddBanner = () => {
    if (sortOrder === "" || type === "" || banner === "" || location === "") {
      // link === "" || linkType === "" ||
      setError("This field is required");
    } else {
      let sort_order = sortOrder;
      let link_type = linkType;
      updateBanner({link, banner, sort_order, type, link_type, location},selectedData.id);
      handleClose();
    }
  };
  return (
    <Modal
      visible={true}
      title={<span>Update Banner </span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">

        <label className="title">
            Link Type<span style={{ color: "red" }}></span>
          </label>
          <Select
            className="field"
            placeholder="Please select a link type!"
            onSelect={(value) => {
              setLinkType(value);
              setLink("");
            }}
            name="linkType"
            value={linkType}
          >
            <Option value="external" key="external">External</Option>
            <Option value="internal" key="internal">Internal</Option>
          </Select>
          <p className="errorMessage">{error}</p>

          {
            linkType === "external" ? (
              <>
                <label className="title">
                  External Link<span style={{ color: "red" }}></span>
                </label>
                <Input
                  className="field"
                  onChange={(e) => {
                    setLink(e.target.value.toString().trim().toLowerCase());
                  }}
                  value={link}
                  placeholder="Enter Link Here.."
                />
                <p className="errorMessage">{error}</p>
              </>
            ) : (
              <>
                <label className="title">
                  Internal Link<span style={{ color: "red" }}></span>
                </label>

                <Select
                  className="field"
                  placeholder="Please select a tag!"
                  onSelect={(value) => {
                    setLink(value);
                  }}
                  name="link"
                  value={link}
                >
                  <Option value="post_trip" key="post_trip">Post Trip</Option>
                </Select>
                <p className="errorMessage">{error}</p>
              </>
            )
          }
          
          
          <label className="title">
            Sort Order<span style={{ color: "red" }}>*</span>
          </label>
          <Input
            className="field"
            onChange={(e) => {
              setSortOrder(e.target.value.toString().trim().toLowerCase());
            }}
            value={sortOrder}
            placeholder="Enter Sort Order Here.."
          />
          <p className="errorMessage">{error}</p>
          <label className="title">
            Type<span style={{ color: "red" }}>*</span>
          </label>
          <Select
            className="field"
            placeholder="Please select a type!"
            onSelect={(value) => {
              setType(value);
            }}
            name="type"
            value={type}
          >
            <Option value="crew" key="crew">Crew</Option>
            <Option value="client" key="client">Client</Option>
          </Select>
          <p className="errorMessage">{error}</p>

          <label className="title">
            Location<span style={{ color: "red" }}>*</span>
          </label>
          <Select
            className="field"
            placeholder="Please select a location!"
            onSelect={(value) => {
              setLocation(value);
            }}
            name="location"
            value={location}
          >
            <Option value="home" key="home">Home</Option>
            <Option value="post" key="post">Crew Application</Option>
            <Option value="startup" key="startup">Startup</Option>
          </Select>
          <p className="errorMessage">{error}</p>

          <br />
          <label className="title">Banner</label>
          <ImageUploader
            folder="banner"
            setImage={updateImage}
            image={banner}
          />
          <p className="errorMessage">{error}</p>
        </div>
        <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            Close
          </button>
          <button className="submit" onClick={handleAddBanner}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateBannerModal;
