import React, { createContext, useState } from "react";
import axios from "axios";
import { errorToast } from "../utils";
import { BEARER_TOKEN_HEADERS } from "../utils";
import { toast } from "react-toastify";

export const JobTypesContext = createContext();

export const JobTypesContextProvider = ({ children }) => {
  const [laoding, setlaoding] = useState(false);

  const [JobTypesLists, setJobTypesLists] = useState([]);
  const getJobTypessList = async (page, paginationStatus) => {
    try {
      setlaoding(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/job_types?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        console.log('jobs types list', res?.data);
        setJobTypesLists(res.data);
      }
      setlaoding(false);
    } catch (err) {
      setlaoding(false);
      console.log(err?.response?.data?.message);
      errorToast(err);
    }
  };

  const createJobTypes = async (name, page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/job_types`,
        { name },
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getJobTypessList(page, paginationStatus);
      setlaoding(false);
    } catch (err) {
      errorToast(err);
      setlaoding(false);
    }
  };

  const updateJobTypes = async (name, id, page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/job_types/${id}`,
        { name },
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getJobTypessList(page, paginationStatus);
      setlaoding(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setlaoding(false);
    }
  };

  const deleteJobTypes = async (id, page, paginationStatus) => {
    setlaoding(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/job_types/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getJobTypessList(page, paginationStatus);
      setlaoding(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setlaoding(false);
    }
  };

  return (
    <JobTypesContext.Provider
      value={{
        getJobTypessList,
        createJobTypes,
        updateJobTypes,
        deleteJobTypes,
        JobTypesLists,
        laoding,
      }}
    >
      {children}
    </JobTypesContext.Provider>
  );
};
