import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Radio, Tooltip } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { ConfirmationModal } from "../../components/Modals/index";
import DataLoader from "../../components/dataLoader";
import { UsersContext } from "../../context/UsersContext";
import { getFormattedDateTime, getFormattedData } from "../../utils";
const { Title } = Typography;

function UsersManagement() {
  const { getUsersList, loading, usersList, deleteUser } =
    useContext(UsersContext);
  const [selectedData, setselectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [page, setPage] = useState(1);
  const { meta, data } = usersList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  useEffect(() => {
    getUsersList(page, true);
  }, [page]);

  useEffect(() => {
    const temp = usersList?.data?.filter((user) =>
      filterStatus === "active"
        ? user?.is_active
        : filterStatus === "inactive"
        ? !user?.is_active
        : user
    );
    console.log("filteredUsers", temp);
    setFilteredUsers(temp);
  }, [filterStatus, data]);
  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, { firstname, lastname }) => {
        return <span>{`${firstname} ${lastname}`}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (data, value) => {
        return <span>{getFormattedData(data)}</span>;
      },
    },
    {
      title: "Activated",
      dataIndex: "is_active",
      key: "is_active",
      render: (data, value) => {
        return <span>{data ? "Yes" : "No"}</span>;
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (data, value) => {
        return <span>{data?.name ? data?.name : `-`}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <div className="actions">
            {/* <span className="update">
              <Tooltip title="Update User">
                <EditOutlined />
              </Tooltip>
            </span> */}
            <span>
              <Tooltip title="Delete User">
                <DeleteOutlined onClick={() => handleConfirmModal(record)} />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  const handleFilterChange = (e) => {
    setFilterStatus(e.target.value);
  };
  //HANDLERS

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteUser(id, page, true);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card
              bordered={false}
              className="criclebox cardbody h-full"
              style={{ minHeight: 430 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>Users</Title>
                  <Paragraph className="lastweek">
                    {/* done this month<span className="blue">40%</span> */}
                  </Paragraph>
                </div>
                <div className="ant-filtertabs mb-24">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <Radio.Group
                      onChange={handleFilterChange}
                      defaultValue="all"
                    >
                      <Radio.Button value="all">ALL</Radio.Button>
                      <Radio.Button value="active">ACTIVE</Radio.Button>
                      <Radio.Button value="inactive">IN-ACTIVE</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <AdminDataTable
                data={filteredUsers}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete User?"}
          description={"Are you sure you want to delete this user?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}
      {loading && <DataLoader />}
    </>
  );
}

export default UsersManagement;
