import React, { useState, createContext } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const ClientContext = createContext();

export const ClientContextProvider = ({ children }) => {
  const [clientList, setClientList] = useState([]);
  const [singleClient, setSingleClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [singleClientLoader, setSingleClientLoader] = useState(false);
  const getClientList = async (page, paginationStatus, query = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/clients?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      setLoading(false);
      setClientList(res?.data);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getSingleClient = async (id) => {
    setSingleClientLoader(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/clients/${id}/full`,
        BEARER_TOKEN_HEADERS
      );
      setSingleClient(res?.data);
      console.log("crew list result", res?.data);
      setSingleClientLoader(false);
    } catch (err) {
      errorToast(err);
      setSingleClientLoader(false);
    }
  };

  const registerClient = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/clients`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message ? res.data.message : "Client added successfully"
      );
      getClientList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateClient = async (data, id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/clients/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(
        res?.data?.message ? res.data.message : "Client Updated successfully"
      );
      getClientList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  const deleteClient = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/clients/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getClientList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <ClientContext.Provider
      value={{
        getSingleClient,
        getClientList,
        clientList,
        data: singleClient,
        singleClientLoader,
        registerClient,
        deleteClient,
        updateClient,
        loading,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
