import React, { useState } from "react";
import { Modal, Input } from "antd";
const UpdateWingTypeModal = ({ handleClose, selectedData, updateData }) => {
  const [name, setName] = useState(selectedData?.name);
  const [nameError, setnameError] = useState("");
  const handleUpdateData = () => {
    if (name === "") {
      setnameError("This field is required");
    } else {
      updateData(name, selectedData?.id);
      handleClose();
    }
  };

  return (
    <Modal
      visible={true}
      title={<span>Update WingType </span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <label className="title">
            Name<span style={{ color: "red" }}>*</span>
          </label>
          <Input
            className="field"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            placeholder="Enter Name Here.."
          />
          <p className="errorMessage">{nameError}</p>
        </div>
        <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            Close
          </button>
          <button className="submit" onClick={handleUpdateData}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateWingTypeModal;
