import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { GenericContext } from "../../../context/genericContext";
import { CrewTypeContext } from "../../../context/CrewTypeContext";

const CreateCrewModal = ({ handleClose, addData }) => {
  const { getCountries, countries } = useContext(GenericContext);
  const { getCrewTypesList, crewLists } = useContext(CrewTypeContext);
  const [form] = Form.useForm();
  useEffect(() => {
    getCountries();
    getCrewTypesList(1, true);
  }, []);
  console.log("crewLists", crewLists);
  const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    console.log("data", data);
    addData(data);
    handleClose();
  };
  return (
    <Modal
      visible={true}
      title={<span>Create Crew</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <label className="title">
                  First Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input name="firstname" placeholder="First name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Last Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"lastname"}
                  rules={[
                    { required: true, message: "Please input your last name!" },
                  ]}
                >
                  <Input name="lastname" placeholder="Last name..." />
                </Form.Item>
              </Col>
            </Row>
            <label className="title">
              Email<span style={{ color: "red" }}>*</span>
            </label>
            <Form.Item
              name={"email"}
              rules={[
                { type: "email", message: "Enter a valid email" },
                { required: true, message: "Please input your email!" },
              ]}
            >
              <Input name="email" placeholder="Email..." />
            </Form.Item>
            <label className="title">
              Password<span style={{ color: "red" }}>*</span>
            </label>
            <Form.Item
              name={"password"}
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 6, message: "Password must be 6 character long" },
              ]}
            >
              <Input.Password
                name="password"
                autoComplete="new-password"
                placeholder="Password..."
              />
            </Form.Item>
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <label className="title">
                  Country<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"country_id"}
                  rules={[
                    { required: true, message: "Please select your country!" },
                  ]}
                >
                  <Select
                    placeholder="Please search or select a country!"
                    name="country_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {countries?.map((country, index) => (
                      <Option value={country?.id} key={index}>
                        {country?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Crew Type<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"crew_type_id"}
                  rules={[
                    { required: true, message: "Please select Crew Type" },
                  ]}
                >
                  <Select
                    placeholder="Please search or select a Crew Type!"
                    name="crew_type_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {crewLists.data?.map((data, index) => (
                      <Option value={data?.id} key={index}>
                        {data?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="is_active" valuePropName="checked">
                  <Checkbox checked={true}>Is active?</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="newsletter" valuePropName="checked">
                  <Checkbox checked={true}>Subscribe to Newsletter?</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateCrewModal;
