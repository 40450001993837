import React, { useState, useContext, useEffect } from "react";

import {Card,Typography,Col,Row,Form,Select,DatePicker,Input,Steps,InputNumber, Slider, Space } from "antd";
import { EnvironmentOutlined, GlobalOutlined, UsergroupAddOutlined, DingdingOutlined, DollarOutline,AlignLeftOutlined, DollarOutlined  } from "@ant-design/icons";
import DataLoader from "../../../components/dataLoader";
import { Redirect } from "react-router-dom";

import { GenericContext } from "../../../context/genericContext";
import { AirportsContext } from "../../../context/AirportContext";
import { CrewTypeContext } from "../../../context/CrewTypeContext";
import { AirCraftContext } from "../../../context/AircraftContext";
import { ManufactureContext } from "../../../context/ManufacturerContext";
import { JobsContext } from "../../../context/JobsContext";
import { getFormattedDate } from "../../../utils";
import { ClientContext } from "../../../context/ClientContext";


const CreatJobFullTime = () => {

  const locations = [{id:1,name:"US Only"},{id:2,name:"Worldwide"}]
  const [finalTripCost, setFinalTripCost] = useState(0);
  const [locationId, setLocationId] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [minPay, setMinPay] = useState(1000);
  const [maxPay, setMaxPay] = useState(1000);

  const { getCountries, countries, 
          getFlightHours, flightHours, 
          getUsStates, usStates, 
          getPaymentTerms, paymentTerms,
          getWorkplaceTypes, workplaceTypes,
          getPositionTypes, positionTypes } = useContext(GenericContext);
  const { getAirportsList, airportsList } = useContext(AirportsContext);
  const { getCrewTypesList, crewTypeLists } = useContext(CrewTypeContext);
  const { getAirCraftsList, aircraftLists } = useContext(AirCraftContext);
  const { getManufacturesList, manufactureLists } = useContext(ManufactureContext);
  const { getClientList, clientList} = useContext(ClientContext);
  const { createJob, loading, jobsRedirect } = useContext(JobsContext);
  
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { Title, Paragraph } = Typography;

  useEffect(() => {
    getCountries();
    getFlightHours();
    getUsStates();
    getPaymentTerms();
    getClientList();
    getWorkplaceTypes()
    getPositionTypes();
    getAirportsList(1,true);
    getCrewTypesList(1,true);
    getAirCraftsList(1,true);
    getManufacturesList();
  }, []);
  
  const kFormatter = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num);
 }

  const handleMinPay = (newValue)=>{
    setMinPay(newValue);
  }

  const handleMaxPay = (newValue)=>{
    setMaxPay(newValue);
  }
  const handleSearchAirport = async (value) => {
    let q = `&q=${value}`;
    getAirportsList(1,true,q);
  }

  const handleChangeManufacturer = (value) => {
    let q = `&manufacturer_id=${value}`;
    getAirCraftsList(1,true,q);
  }

  const handleChangeLocation = (value) => {
    console.log('locationID',value)
    setLocationId(value);
  }


  const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    console.log("first", data);

    let tempData = {
      job_type_id: 1,
      client_id: data.client_id,
      description:data.description,
      crew_type_id:data.crew_type_id,
      countries:data.countries.toString(),
      position_type_id:data.position_type_id,
      title:data.title,
      workplace_type_id:data.workplace_type_id,
      min_pay:minPay,
      max_pay:maxPay,
      email:data.email,
    };

    console.log("TempData",tempData);
    createJob(tempData);
  };

  const handleNext = () => {
    setCurrentStep(currentStep+1);
  }
  const handlePrev = () => {
    setCurrentStep(currentStep-1);
  }

  if(jobsRedirect){
    return <Redirect to={"/admin/trips"} />;
  }else{

  
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
           <div className="add-btn">
              {/* <button onClick={handleCreateModal}>Create Trip</button> */}
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody p-20"
              style={{ minHeight: 360, padding: "20px 50px 50px 50px"}}
            >
      <Title level={5}>Post a Full-Time Job</Title>

<div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">

            <Steps 
              current={currentStep}
              style={{padding:"32px 16px"}}
            >
              <Steps.Step title="Job" icon={<EnvironmentOutlined />} />
              <Steps.Step title="Description" icon={<AlignLeftOutlined />} />
              <Steps.Step title="Earnings" icon={<DollarOutlined />} />
            </Steps>

            {/* Step 1 Fields */}
            <Row gutter={"12"} className={currentStep==0?"stepActive":"stepInActive"}>

              <Col xs={24} sm={24}>
                <label className="title">
                  Job Title<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"title"}
                  rules={[
                    { required: true, message: "Please insert Job Title!" },
                  ]}
                >
                  <Input name="daily_rate" placeholder="Job Title..." />
                </Form.Item>
              </Col>


              <Col xs={24} sm={24}>
                <label className="title">
                  Client<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"client_id"}
                  rules={[
                    { required: true, message: "Please select Airport!" },
                  ]}
                >
                  <Select
                    placeholder="Select Client!"
                    name="client_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {clientList?.map((client, index) => (
                      <Option value={client?.id} key={index}>
                        {`${client?.user?.firstname} ${client?.user?.lastname} ( ${client?.user?.email} )`} 
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Workplace Type<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"workplace_type_id"}
                  rules={[
                    { required: true, message: "Please Workplace type!" },
                  ]}
                >
                  <Select
                    placeholder="Select Workplace type!"
                    name="workplace_type_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {workplaceTypes?.map((workplacetype, index) => (
                      <Option value={workplacetype?.id} key={index}>
                        {workplacetype?.name} 
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Job Location<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"countries"}
                  rules={[
                    { required: true, message: "Please Workplace type!" },
                  ]}
                >
                  <Select
                    placeholder="Add Regions or Countries!"
                    name="countries"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {countries?.map((country, index) => (
                      <Option value={country?.id} key={index}>
                        {country?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Job Type<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"position_type_id"}
                  rules={[
                    { required: true, message: "Please Workplace type!" },
                  ]}
                >
                  <Select
                    placeholder="Select Job Type!"
                    name="position_type_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {positionTypes?.map((positionType, index) => (
                      <Option value={positionType?.id} key={index}>
                        {positionType?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Position<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"crew_type_id"}
                  rules={[
                    { required: true, message: "Please Workplace type!" },
                  ]}
              >
                  <Select
                    placeholder="Select Position!"
                    name="crew_type_id"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {crewTypeLists?.data?.map((crewType, index) => (
                      <Option value={crewType?.id} key={index}>
                        {crewType?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

            </Row>


            {/* Step 2 Fields */}
            <Row gutter={"12"} className={currentStep==1?"stepActive":"stepInActive"}>

            <Col xs={24} sm={24}>
                <label className="title">
                  Description<span style={{ color: "red" }}></span>
                </label>
                <Form.Item
                  name={"description"}
                >
                <Input.TextArea
                  rows={5}
                  className="field"
                  name={"description"}
                  onChange={(e) => {
                    // setMessage(e.target.value);
                  }}
                  // value={message}
                  placeholder="Enter Message Here.."
                />
                </Form.Item>
              </Col>


            </Row>


            {/* Step 3 Fields */}
            <Row gutter={"12"} className={currentStep==2?"stepActive":"stepInActive"}>
                    <label className="title">
                        Minimum Pay<span style={{ color: "red" }}></span> <br />
                    </label>
                    <Col xs={24} sm={24}>
                        <Row>
                            <Col xs={8} sm={8}>
                            {/* <Form.Item
                                name="min_pay"
                            > */}

                            <InputNumber
                                value={kFormatter(minPay)}
                                style={{
                                    // margin: '0 16px',
                                    width:'90%'
                                }}
                                onChange={handleMinPay}
                                name="min_pay"
                                />
                            {/* </Form.Item> */}
                            </Col>
                            <Col xs={16} sm={16}>
                                <Slider
                                min={1000}
                                max={500000}
                                step={1}
                                onChange={handleMinPay}
                                value={minPay}
                                tipFormatter={kFormatter}
                            />
                            </Col>


                        </Row>
                    </Col>          
                       <br />         
                       <br />         
                    <label className="title">
                        Maximum Pay<span style={{ color: "red" }}></span> <br />
                    </label>
                    <Col xs={24} sm={24}>
                        <Row>
                            <Col xs={8} sm={8}>
                            {/* <Form.Item
                                name="max_pay"
                            > */}

                            <InputNumber
                                value={kFormatter(maxPay)}
                                style={{
                                    width:'90%'
                                }}
                                onChange={handleMaxPay}
                                name="max_pay"
                                />
                            {/* </Form.Item> */}
                            </Col>
                            <Col xs={16} sm={16}>
                                <Slider
                                min={1000}
                                max={500000}
                                step={1}
                                onChange={handleMaxPay}
                                value={maxPay}
                                tipFormatter={kFormatter}
                            />
                            </Col>


                        </Row>
                    </Col>          


<br />
<br />
                    <Col xs={24} sm={24}>
                        <label className="title">
                         Alternate Notification<span style={{ color: "red" }}></span>
                        </label>
                        <Form.Item
                        name={"email"}
                        >
                         <Input name="email" placeholder="Email..." />
                        </Form.Item>
                    </Col>                          

            </Row>

          </div>
          <div className="modal-footer d-flex justify-content-end">
            {currentStep==2?<span><button className="submitbtn" type="button" onClick={handlePrev}>Prev</button><button className="submitbtn" type="submit">Submit</button></span>:
             currentStep==0?<button className="submitbtn" type="button" onClick={handleNext}>Next</button>:<span><button className="submitbtn" type="button" onClick={handlePrev}>Prev</button> <button className="submitbtn" type="button" onClick={handleNext}>Next</button></span>}
          </div>
        </Form>
      </div>


            </Card>
          </Col>
        </Row>
      </div>
      {loading && <DataLoader />}

    </>
  );

}

};

export default CreatJobFullTime;
