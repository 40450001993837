import React, { useState, createContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";

export const NotificationsContext = createContext();

export const NotificationsContextProvider = ({ children }) => {
  const [notificationsList, setNotificationsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const getNotificationsList = async (page, paginationStatus, query = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/notifications?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      console.log(res?.data);
      setLoading(false);
      setNotificationsList(res?.data);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const createNotification = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/notifications`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("crew list result", res?.data);
      setLoading(false);
      getNotificationsList(page, paginationStatus);
      toast.success(res?.data?.message);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateNotification = async (data, id, page, paginationStatus) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/notifications/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      getNotificationsList(page, paginationStatus);
      toast.success(res?.data?.message);
    } catch (err) {
      errorToast(err);
    }
  };

  const deleteNotification = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/notifications/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getNotificationsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const resendNotification = async (job_id) => {
    let data = {job_id: job_id};
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/notifications/resend`,
        data,
        BEARER_TOKEN_HEADERS
      );
      setLoading(false);
      toast.success(res?.data?.message);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };


  return (
    <NotificationsContext.Provider
      value={{
        getNotificationsList,
        notificationsList,
        deleteNotification,
        updateNotification,
        createNotification,
        resendNotification,
        loading,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
