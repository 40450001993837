import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/index";
import { Row, Col, Card, Typography } from "antd";
import { getFormattedDateTime, getFormattedData } from "../../../utils/index";
import { AirCraftContext } from "../../../context/AircraftContext";

const AirCraft = () => {
  const { aircraftId } = useParams();
  const { Title } = Typography;
  const { getSingleAirCraft, data, singleaircraftLoader } =
    useContext(AirCraftContext);

  useEffect(() => {
    getSingleAirCraft(aircraftId);
  }, []);
  console.log("data", data);
  if (singleaircraftLoader) {
    return <Loader />;
  } else {
    return (
      <div className="layout-content">
        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <Title level={5}>{data?.name}</Title>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h5 className="detail-title">ICAO</h5>
                <p className="detail-description">
                  {getFormattedData(data?.icao)}
                </p>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h5 className="detail-title">IATA</h5>
                <p className="detail-description">
                  {getFormattedData(data?.iata)}
                </p>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h5 className="detail-title">Type</h5>
                <p className="detail-description">
                  {getFormattedData(data?.type)}
                </p>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h5 className="detail-title">Wing Type</h5>
                <p className="detail-description">
                  {getFormattedData(data?.wing_type?.name)}
                </p>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h5 className="detail-title">Manufacturer</h5>
                <p className="detail-description">
                  {getFormattedData(data?.manufacturer?.name)}
                </p>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h5 className="detail-title">Active</h5>
                <p className="detail-description">
                  {data?.is_active === true ? "Yes" : "No"}
                </p>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h5 className="detail-title">Created At</h5>
                <p className="detail-description">
                  {getFormattedDateTime(data?.created_at)}
                </p>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h5 className="detail-title">Updated At</h5>
                <p className="detail-description">
                  {getFormattedDateTime(data?.updated_at)}
                </p>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    );
  }
};

export default AirCraft;
