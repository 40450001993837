import React, { useState } from "react";
import { Modal, Input } from "antd";
const UpdateRoleModal = ({ handleClose, selectedRole, updateRole }) => {
  const [name, setName] = useState(selectedRole?.name);
  const handleUpdateRole = () => {
    updateRole({ name }, selectedRole?.id);
    handleClose();
  };

  const handleTypeChange = (e) => {
    setName(e?.target?.value);
  };
  return (
    <Modal
      visible={true}
      title={<span>Update Role </span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <label className="title">Name</label>
          <Input className="field" onChange={handleTypeChange} value={name} />
        </div>
        <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            Close
          </button>
          <button className="submit" onClick={handleUpdateRole}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateRoleModal;
